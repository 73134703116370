import "./i18n.js"
import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import { SDKProvider } from "@telegram-apps/sdk-react"
import ThemeLayout from "./layouts/ThemeLayout"
import LocaleLayout from "./layouts/LocaleLayout"
import ErrorBoundary from "./ErrorBoundary"
import AuthLayout from "./layouts/AuthLayout.js"
import MainLayout from "./layouts/MainLayout.js"
import posthog from "posthog-js"
import * as Sentry from "@sentry/react"
import App from "./App.js"

if (
  typeof window !== "undefined" &&
  !window.location.host.includes("127.0.0.1") &&
  !window.location.host.includes("localhost") &&
  !window.location.host.includes("3t856vgf-3000.euw.devtunnels.ms")
)
  posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
    api_host: import.meta.env.VITE_POSTHOG_HOST,
    person_profiles: "identified_only",
  })

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/(?!.*(posthog)).*\.funfiesta\.games/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

ReactDOM.createRoot(document.getElementById("root")!).render(
  <ErrorBoundary>
    <SDKProvider>
      <ThemeLayout>
        <AuthLayout>
          <LocaleLayout>
            <MainLayout>
              <App />
            </MainLayout>
          </LocaleLayout>
        </AuthLayout>
      </ThemeLayout>
    </SDKProvider>
  </ErrorBoundary>
)
