import { Top } from "@/types/user"
import { requestHandler } from "@/utils/requestHandler"

export const getTop = requestHandler<{ token: string }, Top>((params) =>
  fetch(`${import.meta.env.VITE_API}/data/top`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${params.token}`,
    },
  })
)
