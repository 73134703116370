import { Ball } from "@/types"
import { MutableRefObject } from "react"

interface Props {
  ballRef: MutableRefObject<Ball[]>
  drawBalls: () => void
}

export const animateConnection = ({
  ballRef,
  drawBalls,
}: Props): Promise<void> => {
  return new Promise((resolve) => {
    const startTime = performance.now()
    const duration = 200 // 200ms
    const targetX = ballRef.current[0].x

    // Сохраняем начальные позиции
    const startPositions = ballRef.current.map((ball) => ({
      x: ball.x,
      y: ball.y,
    }))

    function animate(currentTime: number) {
      drawBalls()
      const elapsedTime = currentTime - startTime
      const progress = Math.min(elapsedTime / duration, 1)

      // Используем функцию плавности для более естественного движения
      const easeProgress = easeInOutQuad(progress)

      for (let i = 1; i < ballRef.current.length; i++) {
        const ball = ballRef.current[i]
        const startPos = startPositions[i]

        ball.x = startPos.x + (targetX - startPos.x) * easeProgress
      }

      if (progress < 1) {
        requestAnimationFrame(animate)
      } else {
        // Убедимся, что все шары точно в конечной позиции
        for (let i = 1; i < ballRef.current.length; i++) {
          ballRef.current[i].x = targetX
        }
        resolve()
      }
    }

    requestAnimationFrame(animate)
  })
}

function easeInOutQuad(t: number): number {
  return t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2
}
