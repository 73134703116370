import { useBackButton as useBackButtonSdk } from "@telegram-apps/sdk-react"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const useBackButton = (path = "/", deps: any[] = [], onClick?: () => void) => {
  const backButton = useBackButtonSdk()
  const navigate = useNavigate()
  useEffect(() => {
    !deps.length && backButton.show()
    const back = () => {
      if (onClick) onClick()
      else navigate(path)
    }
    backButton.on("click", back)

    return () => backButton.off("click", back)
  }, deps)

  return backButton
}

export default useBackButton
