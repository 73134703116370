import React, { useCallback, useEffect, useRef, useState } from "react"
import { Ball, Block } from "@/types"
import CommonModal from "@/components/common/CommonModal"
import SubHeader from "@/components/common/SubHeader"
import GameModal from "./GameModal"
import GameEndModal from "./GameEndModal"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useUser } from "@/hooks/useUser"
import { formatNumber } from "@/utils/formatNumber"
import {
  postEvent,
  useBackButton as nativeBackButton,
} from "@telegram-apps/sdk-react"
import {
  bombFunctionality,
  rocketFunctionality,
  x2Functionality,
} from "@/utils/boostsFunctionality"
import { useAdsgram } from "@/hooks/useAdgram"
import { useBoosts } from "@/hooks/useBoosts"
import { Boost } from "@/types/boosts"
import { ballRadius, blockHeight, blockWidth, width } from "@brick/const"
import { useToken } from "@/hooks/useToken"
import { buyBoost } from "@/api/boosts"
import { useGame } from "@/hooks/useGame"
import {
  getBoostGame,
  postEndGame,
  postProgressGame,
  postStoryGame,
} from "@/api/game"
import MyPixiComponent from "./PixiComponent"
import { drawGameBlocks } from "@/utils/Game/drawGameBlocks"
import * as PIXI from "pixi.js"
import useBackButton from "@/hooks/useBackButton"
import { DotLottiePlayer, PlayerEvents } from "@dotlottie/react-player"
import explodeAnimation from "@/assets/animations/explode.json"
import { blocksAndBallResult } from "@brick/game"
import { BoostName } from "@brick/common"

interface Props {
  gameModalState: "empty" | "record" | "start"
  score: number
  setGameModalState: React.Dispatch<
    React.SetStateAction<"empty" | "record" | "start">
  >
  setScore: React.Dispatch<React.SetStateAction<number>>
}

const minColor = { r: 255, g: 160, b: 120 }
const maxColor = { r: 255, g: 80, b: 80 }

const Game = ({
  gameModalState,
  setGameModalState,
  score,
  setScore,
}: Props) => {
  const backButton = nativeBackButton()
  const windowWidth = window.innerWidth
  const windowHeight = window.innerHeight

  const scaleX = windowWidth / width
  const scaleY = windowHeight / width
  const scale = Math.min(scaleX, scaleY)

  const { t } = useTranslation()
  const { user, updateUserBalance } = useUser()
  const { gameToken } = useToken()
  const { game, setGame } = useGame()

  const {
    boosts,
    setBoosts,
    currentBooster,
    degreeBoosterCount,
    setCurrentBooster,
  } = useBoosts()
  const navigate = useNavigate()
  const [previousBalls, setPreviousBalls] = useState(0)
  const [boostsIsExist, setBoostIsExist] = useState(false)
  const [gameRestarted, setGameRestarted] = useState(false)
  const [showExitModal, setShowExitModal] = useState(false)
  const [gameStarting, setGameStarting] = useState(true)
  const [explosionPosition, setExplosionPosition] = useState<number>(0)
  const [rocketAnimated, setRocketAnimated] = useState(false)
  const [bombAnimated, setBombAnimated] = useState(false)
  const [showEndModal, setShowEndModal] = useState(false)
  const [x2ballsUsed, setX2BallsUsed] = useState(false)
  const [motivationText, setMotivationText] = useState("")
  const [showBoostModal, setShowBoostModal] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [storyPaid, setStoryPaid] = useState(false)
  const [balanceIsOpened, setBalanceIsOpened] = useState(false)
  const [notEnoughOpened, setNotEnoughOpened] = useState(false)
  const [requestBombOpened, setRequestBombOpened] = useState(false)

  const blockGraphicsRef = useRef<PIXI.Graphics | null>(null)
  const textContainerRef = useRef<PIXI.Container | null>(null)
  const ballRef = useRef<Ball[]>([
    {
      x: (width * scale) / 2,
      y: width * scale - ballRadius * scale - 1,
      speedX: 0,
      speedY: 0,
      launchFrame: 0,
    },
  ])
  const [ballsOptions, setBallsOptions] = useState<{
    left: number
    pushed: boolean
  }>({
    left: (width * scale) / 2,
    pushed: false,
  })

  const isShootingRef = useRef(false)
  const [blocks, setBlocks] = useState<Block[]>([])
  const [ballsLength, setBallsLength] = useState(1)
  const blockRowsRef = useRef(0)

  const sendFieldInfo = (freshBlocks: Block[]) => {
    setGame(gameToken, freshBlocks)
  }

  const resetGameHandle = () => {
    setBlocks([])
    blockRowsRef.current = 0
    ballRef.current = [
      {
        x: (width / 2) * scale,
        y: width * scale - ballRadius * scale - 1,
        speedX: 0,
        speedY: 0,
        launchFrame: 0,
      },
    ]
    setScore(0)
    setShowEndModal(false)
    setGameRestarted(!gameRestarted)
    setBallsLength(1)
    updateUserBalance(user.id, gameToken)
    setMotivationText("")
  }

  const buyBoosts = async () => {
    try {
      await buyBoost({
        token: gameToken,
        currency: "ad",
        kind: "boosts",
        value: currentBooster.name,
        byAd: true,
      })
      setBoosts(gameToken)
      updateUserBalance(user.id, gameToken)
      boostHandler({ ...currentBooster, quantity: 1 })
      setRequestBombOpened(false)
      setShowBoostModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  const buyBoostsForBalance = async () => {
    try {
      if (user.balance > currentBooster.price) {
        await buyBoost({
          token: gameToken,
          currency: "balance",
          kind: "boosts",
          value: currentBooster.name,
          byAd: true,
        })
        setBoosts(gameToken)
        updateUserBalance(user.id, gameToken)
        boostHandler({ ...currentBooster, quantity: 1 })
        setRequestBombOpened(false)
        setShowBoostModal(false)
      } else {
        setShowBoostModal(false)

        setNotEnoughOpened(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const showAd = useAdsgram({
    onReward: () => buyBoosts(),
    onError: () => console.log("error"),
  })

  const drawBlocks = useCallback(
    () =>
      drawGameBlocks({
        blockGraphicsRef,
        textContainerRef,
        blocks,
        minColor,
        maxColor,
        scale,
      }),
    [scale, blocks, blockWidth, blockHeight, minColor, maxColor]
  )

  const boostHandler = async (boost: Boost) => {
    if (!isShootingRef.current) {
      if (boost.quantity === 0) {
        setShowBoostModal(true)
        return
      }
      degreeBoosterCount(boost.name)
      if (boost.name === "rocket" && !isShootingRef.current) {
        const { y, updatedBlocks } = rocketFunctionality(blocks)
        const blocksWithoutInitial: Block[] = updatedBlocks.map((block) => ({
          x: block.x,
          y: block.y,
          strength: block.strength,
          visible: block.visible,
          bonus: block.bonus,
        }))
        await getBoostGame({
          ballsCountAfter: ballRef.current.length,
          ballsCountBefore: ballRef.current.length,
          field: blocksWithoutInitial,
          boostName: BoostName.Rocket,
          id: game.id,
          token: gameToken,
          newRow: blocksWithoutInitial.slice(-6),
        })
        setBlocks(updatedBlocks)
        setExplosionPosition((y || 1) * blockHeight * scale)
        setRocketAnimated(true)
      }
      if (boost.name === "ballsX2" && !isShootingRef.current && !x2ballsUsed) {
        setX2BallsUsed(true)
        const updatedBalls = x2Functionality(ballRef)
        setPreviousBalls(updatedBalls.length / 2)
        await getBoostGame({
          ballsCountAfter: updatedBalls.length,
          ballsCountBefore: ballRef.current.length,
          field: blocks,
          boostName: BoostName.BallsX2,
          id: game.id,
          token: gameToken,
          newRow: blocks.slice(-6),
        })
        ballRef.current = updatedBalls
        setBallsLength((prev) => prev * 2)
      }
      if (boost.name === "bomb" && !isShootingRef.current) {
        const updatedBlocks = bombFunctionality(blocks)
        const blocksWithoutInitial: Block[] = updatedBlocks.map((block) => ({
          x: block.x,
          y: block.y,
          strength: block.strength,
          visible: block.visible,
          bonus: block.bonus,
        }))
        await getBoostGame({
          ballsCountAfter: ballRef.current.length,
          ballsCountBefore: ballRef.current.length,
          field: blocksWithoutInitial,
          boostName: BoostName.Bomb,
          id: game.id,
          token: gameToken,
          newRow: blocksWithoutInitial.slice(-6),
        })
        setBlocks(updatedBlocks)
        setBombAnimated(true)
      }
      setBoosts(gameToken)
      drawBlocks()
      setRequestBombOpened(false)
    }
  }

  const sendProgressFieldInfo = async (
    directionX: number,
    directionY: number
  ) => {
    const initialX = Math.round(ballRef.current[0].x / scale)
    const balls = ballRef.current.length

    const { blocksArray, ballsArray } = blocksAndBallResult({
      directionX,
      directionY,
      blocks,
      balls,
      initialX,
    })

    await postProgressGame({
      token: gameToken,
      id: game.id,
      directionX,
      directionY,
      ballsBefore: balls,
      ballsAfter: ballsArray.length,
      field: blocksArray,
      initialX,
      newRow: blocks.slice(-6),
    })
  }

  const sendStoryGame = async () => {
    const storyResponse = await postStoryGame({
      token: gameToken,
      id: game.id,
    })
    if (storyResponse.code === "success")
      postEvent("web_app_share_to_story" as any, {
        media_url: storyResponse.data.url,
        text: storyResponse.data.text,
        widget_link: storyResponse.data.widget_link,
      })
  }

  const sendEndGame = async () => {
    try {
      setLoading(true)
      const endResponse = await postEndGame({
        token: gameToken,
        id: game.id,
        ballsCount: ballRef.current.length,
        field: blocks,
      })
      if (endResponse.code === "success") {
        setMotivationText(endResponse.data.motivationText)
        setStoryPaid(endResponse.data.storyPaid)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (user.score !== 0) setGameModalState("record")
    else setGameModalState("start")
  }, [])

  useEffect(() => {
    if (boosts.find((boost) => boost.name)) setBoostIsExist(true)
    else setBoostIsExist(false)
  }, [boosts])

  useEffect(() => {
    if (showEndModal) sendEndGame()
  }, [showEndModal])

  const backBehavior = () => {
    if (showEndModal) {
      resetGameHandle()
      setGameModalState("record")
      setShowEndModal(false)
    } else setShowExitModal(true)
  }

  useEffect(() => {
    if (gameModalState !== "empty") backButton.hide()
    else backButton.show()
  }, [gameModalState])

  useEffect(() => {
    if (gameModalState === "empty" && !game.id) setGameStarting(true)
    else setGameStarting(false)
  }, [game.id, gameModalState])

  useBackButton("", [showEndModal], backBehavior)

  useEffect(() => {
    if (requestBombOpened) setCurrentBooster("bomb")
  }, [requestBombOpened])

  useEffect(() => {
    if (!ballsOptions.pushed) setX2BallsUsed(false)
  }, [ballsOptions.pushed])

  return (
    <div className="relative h-screen w-full flex-col items-center justify-between overflow-hidden">
      {gameModalState !== "empty" && (
        <div className="flex w-full items-center justify-between px-3 pt-3">
          <div className="rounded-full">
            <img
              className="rounded-full"
              width={36}
              height={36}
              src={user.avatar || "/svg/default.svg"}
            />
          </div>
          <div className="bg-light-gray/50 flex items-center justify-center rounded-full px-6 py-2">
            <p className="font-sfUiSemibold mr-1 text-base text-black dark:text-white">
              {formatNumber(user.balance, 0).truncatedNumber}
            </p>
            <div className="flex items-center gap-x-3">
              <img src="/svg/coin.svg" />
              <img
                onClick={() => setBalanceIsOpened(true)}
                width={16}
                height={16}
                src="/svg/questionMark.svg"
              />
            </div>
          </div>
        </div>
      )}

      <div
        className={`absolute ${gameModalState === "empty" ? "top-[calc(50%+18px)]" : "top-[50%]"} my-auto mt-3 w-full -translate-y-1/2`}
      >
        {gameModalState !== "empty" && (
          <GameModal
            gameModalState={gameModalState}
            setGameModalState={setGameModalState}
            user={user}
            setBallsOptions={setBallsOptions}
            scale={scale}
          />
        )}
        {gameModalState === "empty" && (
          <div className="flex h-[34px] w-full items-center justify-between px-3">
            <div className="flex items-center gap-x-1">
              <h3 className="font-sfUiBold text-gray text-sm uppercase">
                {t("game.score")}:
              </h3>
              <div className="flex items-center gap-x-1">
                <img width={23} height={14} src="/images/brick.png" />
                <p className="font-sfUiBold text-sm text-black dark:text-white">
                  {score}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-x-1">
              <h3 className="font-sfUiBold text-gray text-sm uppercase">
                {t("game.record")}:
              </h3>
              <div className="flex items-center gap-x-1">
                <img width={23} height={14} src="/images/brick.png" />
                <p className="font-sfUiBold text-sm uppercase text-black dark:text-white">
                  {user.score > score ? user.score : score}
                </p>
              </div>
            </div>
          </div>
        )}
        {rocketAnimated && (
          <div
            className={`fixed left-1/2 z-50 h-40 w-40 -translate-x-1/2`}
            style={{ pointerEvents: "none", top: explosionPosition }}
          >
            <DotLottiePlayer
              src={explodeAnimation}
              autoplay
              onEvent={(event: PlayerEvents) => {
                if (event === PlayerEvents.Complete) setRocketAnimated(false)
              }}
            />
          </div>
        )}
        {bombAnimated && (
          <div
            className={`fixed left-0 z-50 flex items-center justify-between`}
            style={{
              pointerEvents: "none",
              bottom: 3 * scale * blockHeight,
            }}
          >
            {blocks.slice(-6).map(() => (
              <div
                className={`h-${blockWidth * scale} w-${blockWidth * scale}`}
              >
                <DotLottiePlayer
                  src={explodeAnimation}
                  autoplay
                  onEvent={(event: PlayerEvents) => {
                    if (event === PlayerEvents.Complete) setBombAnimated(false)
                  }}
                />
              </div>
            ))}
          </div>
        )}
        <MyPixiComponent
          ballRef={ballRef}
          blocks={blocks}
          setBlocks={setBlocks}
          blockRowsRef={blockRowsRef}
          setScore={setScore}
          setShowEndModal={setShowEndModal}
          ballsLength={ballsLength}
          setBallsLength={setBallsLength}
          isShootingRef={isShootingRef}
          scale={scale}
          sendProgressFieldInfo={sendProgressFieldInfo}
          setBallsOptions={setBallsOptions}
          ballsOptions={ballsOptions}
          gameModalState={gameModalState}
          gameRestarted={gameRestarted}
          setRequestBombOpened={setRequestBombOpened}
          drawBlocks={drawBlocks}
          blockGraphicsRef={blockGraphicsRef}
          sendFieldInfo={sendFieldInfo}
          previousBalls={previousBalls}
          setPreviousBalls={setPreviousBalls}
          gameStarting={gameStarting}
        />
        <div style={{ height: 30 }}>
          {!ballsOptions.pushed && (
            <h2
              style={{
                color: "#0096FF",
                width: "min-content",
                left: ballsOptions.left - 12,
                fontWeight: "bold",
                position: "absolute",
              }}
            >
              x{ballsLength}
            </h2>
          )}
        </div>
        {gameModalState === "empty" && (
          <div className="flex w-full items-center justify-center gap-x-10">
            {boosts
              .filter((boost) => boost.name !== "bomb")
              .map((boost) => (
                <div
                  key={boost.name}
                  onClick={() => {
                    setCurrentBooster(boost.name)
                    boostHandler(boost)
                  }}
                  className="dark:bg-dark-canvas border-text-gray dark:border-modal relative rounded-xl border border-[1px] bg-white px-7 py-3"
                >
                  <img
                    width={25}
                    height={25}
                    src={`/images/${boost.name}.png`}
                  />
                  <p className="font-sfUiRegular mt-1 text-center text-xs">
                    x{boost.quantity > -1 ? boost.quantity : 0}
                  </p>
                </div>
              ))}
          </div>
        )}
      </div>

      {gameModalState !== "empty" && boostsIsExist && (
        <div
          className="bg-text-gray dark:bg-dark-canvas border-text-gray dark:border-modal fixed bottom-[92px] left-1/2 z-20 flex w-[245px] -translate-x-1/2 transform items-center rounded-full border-[1px] border-solid py-[16px]"
          onClick={() => navigate("/boosts")}
        >
          <ul className="flex w-full items-center justify-center">
            {boosts.map((boost) => (
              <li key={boost.name} className="mr-2 flex items-center">
                <img src={`/images/${boost.name}.png`} className="h-6 w-6" />
                <p className="font-sfUiSemibold ml-1 mr-1 text-base">
                  x{boost.quantity}
                </p>
              </li>
            ))}
            <li className="h-6 w-6">
              <img
                className="h-6 w-6"
                width={24}
                height={24}
                src="/svg/arrowright.svg"
              />
            </li>
          </ul>
        </div>
      )}

      <CommonModal
        showModal={balanceIsOpened}
        setShowModal={setBalanceIsOpened}
        buttonContent={
          <div className={`flex w-full items-center justify-center`}>
            <button
              onClick={() => setBalanceIsOpened(false)}
              className="bg-button-color w-full rounded-full py-2.5 text-white"
            >
              {t("others.ok")}
            </button>
          </div>
        }
        content={
          <div className="w-full flex-col items-center justify-center">
            <img
              className="m-auto mb-5 h-[75px] w-[75px]"
              width={75}
              height={75}
              src={"/images/gameBig.png"}
            />
            <div className="mb-3 flex justify-center">
              <h2 className="font-sfUiBold text-center text-2xl text-black dark:text-white">
                {t("boostsPage.playAndCollectCoins")}
              </h2>
            </div>
            <SubHeader
              styles="text-center text-sm mb-4"
              title={t("boostsPage.playGameAndCompleteTasksToEarnCoins")}
            />
          </div>
        }
      />
      {showEndModal && (
        <GameEndModal
          resetGameHandle={resetGameHandle}
          score={score}
          motivationText={motivationText}
          user={user}
          storyPaid={storyPaid}
          loading={loading}
          setGameModalState={setGameModalState}
          sendStoryGame={sendStoryGame}
        />
      )}
      <CommonModal
        buttonContent={
          <div className={`flex w-full items-center justify-center`}>
            <button
              onClick={() => buyBoostsForBalance()}
              className="bg-button-color flex items-center justify-center rounded-full px-12 py-3 text-white"
            >
              <img src="/svg/coin.svg" />
              <p className="ml-2">{currentBooster.price || 500}</p>
            </button>
            <div className="bg-text-gray ml-2 mr-2 h-[50px] w-[1px]" />
            <button
              onClick={showAd}
              className="bg-purple flex h-[45px] w-[49px] items-center justify-center rounded-full"
            >
              <img
                className="mx-6 my-5 h-[16px] w-[20px]"
                width={20}
                height={20}
                src="/images/horn.png"
              />
            </button>
          </div>
        }
        showModal={showBoostModal}
        setShowModal={setShowBoostModal}
        content={
          <>
            <img
              className="m-auto mb-3"
              width={100}
              src={`/images/${currentBooster.name}Big.png`}
            />
            <h2 className="font-sfUiBold mb-3 text-center text-xl text-black dark:text-white">
              {t("boostsPage.boosterExpired", {
                booster: t("boostsPage." + currentBooster.name),
              })}
              :&#40;
            </h2>
            <SubHeader
              styles="text-center mb-5 text-sm"
              title={t("boostsPage.youCanBuyItNow")}
            />
          </>
        }
      />
      <CommonModal
        buttonContent={
          <div className="flex w-full items-center justify-center">
            <button
              onClick={() => setNotEnoughOpened(false)}
              className="bg-button-color flex items-center justify-center rounded-full px-12 py-3 text-white"
            >
              <p className="ml-2">Ok</p>
            </button>
          </div>
        }
        showModal={notEnoughOpened}
        setShowModal={setNotEnoughOpened}
        content={
          <>
            <h2 className="font-sfUiBold mb-3 text-center text-xl text-black dark:text-white">
              {t("game.notEnoughCoins")}
            </h2>
            <SubHeader
              styles="text-center mb-5 text-sm"
              title={t("game.notEnoughForBuy")}
            />
          </>
        }
      />
      <CommonModal
        buttonContent={
          <div className="flex w-full flex-col">
            {boosts.find((boost) => boost.name === "bomb")?.quantity !== 0 ? (
              <div className="w-full">
                <button
                  onClick={() => {
                    setCurrentBooster("bomb")
                    boostHandler({
                      ...currentBooster,
                      name: "bomb",
                      quantity: 1,
                    })
                  }}
                  className="bg-button-color flex w-full items-center justify-center rounded-full px-12 py-3 text-white"
                >
                  <p>{t("game.use")}</p>
                </button>
              </div>
            ) : (
              <div className={`flex w-full items-center justify-center`}>
                <button
                  onClick={() => {
                    setCurrentBooster("bomb")
                    buyBoostsForBalance()
                  }}
                  className="bg-button-color flex items-center justify-center rounded-full px-12 py-3 text-white"
                >
                  <img src="/svg/coin.svg" />
                  <p className="ml-2">{currentBooster.price || 500}</p>
                </button>
                <div className="bg-text-gray ml-2 mr-2 h-[50px] w-[1px]" />
                <button
                  onClick={() => {
                    setCurrentBooster("bomb")
                    showAd()
                  }}
                  className="bg-purple flex h-[45px] w-[49px] items-center justify-center rounded-full"
                >
                  <img
                    className="mx-6 my-5 h-[16px] w-[20px]"
                    width={20}
                    height={20}
                    src="/images/horn.png"
                  />
                </button>
              </div>
            )}
            <div className="my-3 w-full">
              <button
                onClick={() => {
                  setShowEndModal(true)
                  setRequestBombOpened(false)
                }}
                className="bg-orange flex w-full items-center justify-center rounded-full px-12 py-3 text-white"
              >
                <p>{t("game.giveUp")}</p>
              </button>
            </div>
          </div>
        }
        onClose={() => setShowEndModal(true)}
        showModal={requestBombOpened}
        setShowModal={setRequestBombOpened}
        content={
          <>
            <img
              className="m-auto mb-5 h-[75px] w-[75px]"
              width={75}
              height={75}
              src={"/images/bombBig.png"}
            />
            <h2 className="font-sfUiBold mb-3 text-center text-xl text-black dark:text-white">
              {t("game.oopsLose")}
            </h2>
            <SubHeader
              styles="text-center mb-5 text-sm"
              title={t("game.helpBomb")}
            />
          </>
        }
      />
      <CommonModal
        buttonContent={
          <div className={`flex w-full items-center justify-center`}>
            <button
              onClick={() => setShowExitModal(false)}
              className="bg-button-color flex items-center justify-center rounded-full px-9 py-3 text-white"
            >
              <p className="font-sfUiRegular text-base text-white">
                {t("others.no")}
              </p>
            </button>
            <div className="bg-text-gray/50 ml-2 mr-2 h-[50px] w-[1px]" />
            <button
              onClick={() => {
                setShowEndModal(true)
                setShowExitModal(false)
                ballRef.current = [
                  {
                    x: (width * scale) / 2,
                    y: width * scale - ballRadius * scale - 1,
                    speedX: 0,
                    speedY: 0,
                    launchFrame: 0,
                  },
                ]
              }}
              className="bg-button-color flex items-center justify-center rounded-full px-9 py-3 text-white"
            >
              <p className="font-sfUiRegular text-base text-white">
                {t("others.yes")}
              </p>
            </button>
          </div>
        }
        showModal={showExitModal}
        setShowModal={setShowExitModal}
        content={
          <>
            <h2 className="font-sfUiBold mb-3 text-center text-xl text-black dark:text-white">
              {t("game.completeTheGame")}
            </h2>
            <SubHeader
              styles="text-center mb-5 text-sm"
              title={t("game.goOut")}
            />
          </>
        }
      />
    </div>
  )
}

export default Game
