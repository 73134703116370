import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Logo from "@/components/common/Logo"

const Menu = () => {
  const { t } = useTranslation()
  return (
    <div className="dark:bg-dark-canvas border-hint-color/20 fixed bottom-4 left-1/2 flex -translate-x-1/2 items-center justify-between rounded-3xl border border-solid bg-white px-8 py-3 drop-shadow-lg">
      <Link to={"/"} className="pr-5">
        <div className="m-auto h-7 w-7">
          <Logo image={"/images/game.png"} size={7} />
        </div>
        <p className="text-xs">{t("tabList.play")}</p>
      </Link>
      <div className="bg-light-gray/50 h-7 w-px"></div>
      <Link to={"/quests"} className="px-5">
        <div className="m-auto h-7 w-7">
          <Logo image={"/images/quests.png"} size={7} />
        </div>

        <p className="text-xs">{t("tabList.quests")}</p>
      </Link>
      <div className="bg-light-gray/50 h-7 w-px"></div>
      <Link to={"/friends"} className="px-5">
        <div className="m-auto h-7 w-7">
          <Logo image={"/images/friends.png"} size={7} />
        </div>
        <p className="text-xs">{t("tabList.friends")}</p>
      </Link>
      <div className="bg-light-gray/50 h-7 w-px"></div>
      <Link to={"/rate"} className="pl-5">
        <div className="m-auto h-7 w-7">
          <Logo image={"/images/rating.png"} size={7} />
        </div>
        <p className="text-xs">{t("tabList.rating")}</p>
      </Link>
    </div>
  )
}

export default Menu
