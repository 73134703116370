import { User } from "@/types/user"
import { requestHandler } from "@/utils/requestHandler"

interface Props {
  data: string
}

interface GameAuthProps {
  initDataRaw: string
}

export const auth = requestHandler<Props, { token: string }>((params) =>
  fetch(import.meta.env.VITE_CORE_API_URL + "/auth/user-tma", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      dataTma: params?.data,
      applicationId: import.meta.env.VITE_CORE_API_APP_ID,
    }),
  })
)

export const gameAuth = async ({
  initDataRaw,
}: GameAuthProps): Promise<{
  code: "success" | "error"
  data: { token: string; user: User }
}> => {
  try {
    const request = await fetch(`${import.meta.env.VITE_API}/token`, {
      headers: {
        Authorization: ("Bearer " + initDataRaw) as string,
      },
    })
    const res = await request.json()
    return {
      code: "success",
      data: res,
    }
  } catch (error) {
    return {
      code: "error",
      data: {
        token: "",
        user: {
          alive: false,
          avatar: "",
          score: 0,
          balance: 0,
          ban: false,
          country: "",
          dignities: [],
          from: "",
          id: 0,
          lang: 0,
          languageCode: "",
          lastActivity: "",
          lastGameAt: "",
          lastNotifiedAt: "",
          name: "",
          openedMiniApp: false,
          touchedAfterNotification: false,
          username: "",
          image: "",
        },
      },
    }
  }
}
