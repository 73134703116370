import { Ball, Block } from "@/types"
import { MutableRefObject } from "react"

export const rocketFunctionality = (blocks: Block[]) => {
  let y
  const updatedBlocks = blocks.map((block, index) => {
    if ((blocks.length / 2) % 2 === 0) {
      if (
        index === Math.floor(blocks.length / 2 - 3) ||
        index === Math.floor(blocks.length / 2 - 4) ||
        index === Math.floor(blocks.length / 2 + 2) ||
        index === Math.floor(blocks.length / 2 + 3)
      ) {
        block.strength = block.strength - blocks.length * 2
        if (block.strength <= 0) {
          block.strength = 0
          block.visible = false
        }
        if (index === Math.floor(blocks.length / 2 - 4)) y = block.y
      }
    } else if (
      index === Math.floor(blocks.length / 2) ||
      index === Math.floor(blocks.length / 2 - 1) ||
      index === Math.floor(blocks.length / 2 + 5) ||
      index === Math.floor(blocks.length / 2 + 6)
    ) {
      block.strength = block.strength - blocks.length * 2
      if (block.strength <= 0) {
        block.strength = 0
        block.visible = false
      }
      if (index === Math.floor(blocks.length / 2 - 1)) y = block.y
    }

    return block
  })
  return { y, updatedBlocks }
}

export const x2Functionality = (ballsRef: MutableRefObject<Ball[]>) => {
  const newBalls: Ball[] = []

  for (const ball of ballsRef.current)
    newBalls.push({
      x: ball.x,
      y: ball.y,
      speedX: 0,
      speedY: 0,
      launchFrame: 0,
    })

  const updatedBalls = [...ballsRef.current, ...newBalls]
  return updatedBalls
}

export const bombFunctionality = (blocks: Block[]) => {
  const updatedBlocks = blocks.slice(6)
  return updatedBlocks
}
