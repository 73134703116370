import { create, StoreApi, UseBoundStore } from "zustand"

import { getQuests, getQuestsByUser } from "@/api/quests"
import { QuestsArray, QuestsByUserArray } from "@/types/quests"

interface QuestsState {
  quests: QuestsArray
  setLocalQuests: () => void
  setQuestAction: (updatedQuests: QuestsArray) => void
  setQuests: (token: string, language: string) => Promise<void>
  setQuestsByUser: (token: string, language: string) => Promise<void>
}

export const useQuests: UseBoundStore<StoreApi<QuestsState>> =
  create<QuestsState>((set) => ({
    quests: [],
    setQuests: async (token: string, language: string) => {
      const questsResponse = await getQuests({ token, language })
      if (questsResponse.code === "success") {
        const questsByUserLocal = localStorage.getItem("questsByUser")
        let parsedQuestsByUser: QuestsByUserArray = []
        if (questsByUserLocal)
          parsedQuestsByUser = JSON.parse(questsByUserLocal)

        const updatedQuests: QuestsArray = questsResponse.data.map((quest) => ({
          ...quest,
          id: quest.id,
          name: quest.name,
          description: quest.description,
          link: quest.link,
          insideTg: quest.insideTg,
          image: quest.image,
          reward: quest.reward,
          rewardTokenId: quest.rewardTokenId,
          status: quest.status,
          actionId: quest.actionId,
          createdAt: quest.createdAt,
          action: quest.action,
          tasksXUser: parsedQuestsByUser
            ? parsedQuestsByUser
                .find((item) => item.id === quest.id)
                ?.tasksXUser.find((task) => task.taskId === quest.actionId)
            : undefined,
        }))
        localStorage.setItem("quests", JSON.stringify(updatedQuests))
        set(() => ({ quests: updatedQuests }))
      }
    },
    setQuestAction: (updatedQuests: QuestsArray) => {
      localStorage.setItem("quests", JSON.stringify(updatedQuests))
      set(() => ({ quests: updatedQuests }))
    },
    setQuestsByUser: async (token: string, language: string) => {
      const questsResponse = await getQuestsByUser({ token, language })
      if (questsResponse.code === "success") {
        localStorage.setItem(
          "questsByUser",
          JSON.stringify(questsResponse.data)
        )
        set((state) => ({
          quests: state.quests.map((quest) => {
            const questItem = questsResponse.data.find(
              (item) => quest.id === item.id
            )
            const actionItem = questItem?.tasksXUser.find(
              (action) => quest.actionId === action.taskId
            )
            if (actionItem) {
              quest.tasksXUser = actionItem
              return quest
            }
            return quest
          }),
        }))
      }
    },
    setLocalQuests: () => {
      const dataForParse = localStorage.getItem("quests")
      if (dataForParse) {
        const localStorageData = JSON.parse(dataForParse)
        set(() => ({ quests: localStorageData }))
      }
    },
  }))
