import CardWrapper from "@/components/common/CardWrapper"
import { Friend } from "@/types/user"

interface Props {
  friend: Friend
}

const FriendsItem = ({ friend }: Props) => {
  return (
    <div className="mb-3 w-full">
      <CardWrapper
        bgColor={{ dark: "bg-dark-canvas", light: "white" }}
        styles="px-3 py-2 rounded-2xl"
        leftSide={
          <div
            className={` ${!friend.score && "opacity-50"} ml-[12px] flex items-center justify-between`}
          >
            <div className="flex-col text-left">
              <h4 className="text-gray font-sfUiSemibold text-sm dark:text-white">
                {friend.name}
              </h4>
              <div className="flex items-center">
                <div className="h-[14px] w-[23px]">
                  <img width={23} height={14} src="/images/brick.png" />
                </div>
                <p className="font-sfUiSemibold ml-2 text-base">
                  {friend.score}
                </p>
              </div>
            </div>
          </div>
        }
        image={friend.avatar || friend.image || "/svg/default.svg"}
        imageStyle="w-9 h-9"
        square
        size={9}
        rightSide={
          <div
            className={`${!friend.score && "opacity-50"} mr-8 flex w-full items-center gap-x-1`}
          >
            <img width={16} height={16} src="/svg/coin.svg" />
            <p className="font-sfUiSemibold text-sm">+{friend.bonus}</p>
            <img
              src={`/svg/${!friend.score || friend.bonus === 0 ? "uncheck.svg" : "complete.svg"}`}
            />
          </div>
        }
      ></CardWrapper>
    </div>
  )
}

export default FriendsItem
