import { ReactNode } from "react"

import Logo from "./Logo"

interface Props {
  bgColor?: {
    dark?: string
    light?: string
  }
  children?: ReactNode
  image?: string
  imageStyle?: string
  leftSide?: ReactNode
  rightSide?: ReactNode
  size?: number
  square?: boolean
  styles?: string
}

const CardWrapper = ({
  children,
  styles,
  bgColor,
  leftSide,
  rightSide,
  image,
  imageStyle,
  square,
  size = 14,
}: Props) => {
  return (
    <div
      className={`flex w-full justify-start bg-${
        bgColor?.light || "white"
      } dark:${bgColor?.dark || "bg-sec-bg-color"} ${styles}`}
    >
      {children}
      {leftSide && (
        <div className="flex w-full items-center justify-start">
          <div className={`${size ? `min-h-${size}` : ""}`}>
            {image && (
              <div className={imageStyle ? imageStyle : ""}>
                <Logo
                  image={image}
                  size={size}
                  styles={square ? "rounded-xl" : "rounded-full"}
                />
              </div>
            )}
          </div>
          {leftSide}
        </div>
      )}
      {rightSide && (
        <div className="flex items-center justify-end">{rightSide}</div>
      )}
    </div>
  )
}

export default CardWrapper
