import { getRank } from "@/api/rank"
import { getTop } from "@/api/top"
import { User } from "@/types/user"
import { create, StoreApi, UseBoundStore } from "zustand"

interface RateType {
  rank: {
    usersCount: number
    userPlace: number | null
  }
  setLocalRank: () => void
  setLocalTop: () => void
  setRank: (token: string) => void
  setTop: (token: string) => void
  top: {
    users: User[]
    userPlace: number | null
  }
}

export const useRate: UseBoundStore<StoreApi<RateType>> = create<RateType>(
  (set) => ({
    rank: {
      usersCount: 0,
      userPlace: null,
    },
    top: {
      users: [],
      userPlace: null,
    },
    setRank: async (token: string) => {
      const data = await getRank({ token })
      if (data.code === "success" && data.data.usersCount) {
        localStorage.setItem("rank", JSON.stringify(data.data))
        set(() => ({ rank: data.data }))
      }
    },
    setTop: async (token: string) => {
      const data = await getTop({ token })
      if (data.code === "success" && data.data.users) {
        const sortedTop = data.data.users.sort((a, b) => b.score - a.score)
        localStorage.setItem(
          "top",
          JSON.stringify({
            users: sortedTop,
            userPlace: data.data.userPlace,
          })
        )

        set(() => ({
          top: {
            users: sortedTop,
            userPlace: data.data.userPlace,
          },
        }))
      }
    },
    setLocalRank: () => {
      const dataForParse = localStorage.getItem("rank")
      if (dataForParse) {
        const localStorageData = JSON.parse(dataForParse)
        set(() => ({ rank: localStorageData }))
      }
    },
    setLocalTop: () => {
      const dataForParse = localStorage.getItem("top")
      if (dataForParse) {
        const localStorageData = JSON.parse(dataForParse)
        set(() => ({ top: localStorageData }))
      }
    },
  })
)
