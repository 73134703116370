import { Rank } from "@/types/user"
import { requestHandler } from "@/utils/requestHandler"

export const getRank = requestHandler<{ token: string }, Rank>((params) =>
  fetch(`${import.meta.env.VITE_API}/data/rank`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${params.token}`,
    },
  })
)
