import { User } from "@/types/user"
import { requestHandler } from "@/utils/requestHandler"

export const getUserInfo = requestHandler<
  { id: number | undefined; token: string },
  User
>((params) =>
  fetch(`${import.meta.env.VITE_API}/data/getUserInfo/` + params.id, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${params.token}`,
    },
  })
)
