import { auth } from "@/api/auth"

interface Props {
  initDataRaw: string
  setToken: (token: string) => void
}

export const authorization = async ({ initDataRaw, setToken }: Props) => {
  if (initDataRaw) {
    const authResponse = await auth({
      data: initDataRaw,
    })

    if (authResponse.code === "success") {
      const tokenData = {
        token: authResponse.data.token,
        tokenReceivedAt: Date.now(),
      }
      localStorage.setItem("token", JSON.stringify(tokenData))
      setToken(authResponse.data.token)
    }
  } else {
    const localToken = localStorage.getItem("token")
    if (localToken) {
      const parsedToken: { token: string; tokenReceivedAt: string } =
        JSON.parse(localToken)
      setToken(parsedToken.token)
    }
  }
}
