import Menu from "@/components/common/Menu"
import Friends from "@/components/pages/Friends"
import useBackButton from "@/hooks/useBackButton"
import { useFriends } from "@/hooks/useFriends"
import { useToken } from "@/hooks/useToken"
import { useUser } from "@/hooks/useUser"
import { postEvent, Utils } from "@telegram-apps/sdk-react"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

const FriendsPage = () => {
  const utils = new Utils("7.0", () => Math.random().toString(), postEvent)
  const { t } = useTranslation()
  const { gameToken } = useToken()
  const { user } = useUser()
  const { setFriends } = useFriends()

  const getFriends = () => {
    setFriends(gameToken)
  }

  const shareHandler = () =>
    utils.openTelegramLink(
      encodeURI(t("friendsPage.shareLink", { user: user.id }))
    )

  useEffect(() => {
    if (gameToken) getFriends()
  }, [gameToken])

  useBackButton()

  return (
    <div className="h-full overflow-scroll">
      <Friends />
      <Menu />
      <div className="relative px-6">
        <button
          onClick={shareHandler}
          className="bg-button-color font-sfUiBold fixed bottom-[92px] left-1/2 w-[calc(100%-24px)] -translate-x-1/2 rounded-[18px] py-[15px] text-xl text-white"
        >
          <div className="flex items-center justify-center">
            <img className="mr-2" src="/svg/plusFriend.svg" />
            {t("friendsPage.inviteFriend")}
          </div>
        </button>
      </div>
    </div>
  )
}

export default FriendsPage
