export function lineRectIntersection(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  left: number,
  top: number,
  right: number,
  bottom: number
) {
  const intersections = [
    lineLineIntersection(x1, y1, x2, y2, left, top, right, top),
    lineLineIntersection(x1, y1, x2, y2, right, top, right, bottom),
    lineLineIntersection(x1, y1, x2, y2, right, bottom, left, bottom),
    lineLineIntersection(x1, y1, x2, y2, left, bottom, left, top),
  ].filter(Boolean)

  if (intersections.length === 0) return null

  return intersections.reduce((closest, current) => {
    if (closest && current) {
      const closestDist =
        Math.pow(closest.x - x1, 2) + Math.pow(closest.y - y1, 2)
      const currentDist =
        Math.pow(current.x - x1, 2) + Math.pow(current.y - y1, 2)
      return currentDist < closestDist ? current : closest
    } else return current
  })
}

function lineLineIntersection(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
  x4: number,
  y4: number
) {
  const denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1)
  if (denom === 0) return null
  const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denom
  const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denom
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) return null
  return { x: x1 + ua * (x2 - x1), y: y1 + ua * (y2 - y1) }
}
