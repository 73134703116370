interface CommonHeaderProps {
  token: string
  language: string
}

export const DefaultHeaders = ({ token, language }: CommonHeaderProps) => {
  return {
    Authorization: `Bearer ${token}`,
    "accept-language": language,
  }
}
