import CommonHeader from "@/components/common/CommonHeader"
import { useTranslation } from "react-i18next"
import RateItem from "./RateItem"
import { useRate } from "@/hooks/useRate"
import { useUser } from "@/hooks/useUser"

const Rate = () => {
  const { t } = useTranslation()
  const { rank, top } = useRate()
  const { user } = useUser()

  return (
    <div className="mt-3 px-3">
      <CommonHeader title={t("ratingPage.rating")} styles="mb-4" />
      <div className="fixed bottom-[156px] top-[50px] w-[calc(100%-24px)] overflow-auto rounded-xl">
        {top?.users?.length !== 0 &&
          top?.users?.slice(0, 3).map((item, index) => (
            <div className="mb-3">
              <RateItem key={item.id} user={item} rate={index + 1} />
            </div>
          ))}
        {top?.users?.length > 3 && (
          <ul className="dark:bg-dark-canvas rounded-xl bg-white px-3">
            {top?.users?.slice(3, top?.users.length).map((item, index) => (
              <li>
                <RateItem
                  key={item.id}
                  rate={index + 4}
                  user={item}
                  last={
                    index === top?.users?.slice(3, top?.users.length).length - 1
                  }
                />
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="dark:bg-dark-canvas font-sfUiBold fixed bottom-[92px] w-[calc(100%-24px)] rounded-xl bg-white text-xl text-white drop-shadow-lg">
        <RateItem
          rate={top.userPlace ? top.userPlace : 0}
          user={user}
          last
          self
        />
      </div>
    </div>
  )
}

export default Rate
