import Menu from "@/components/common/Menu"
import Rate from "@/components/pages/Rate"
import useBackButton from "@/hooks/useBackButton"
import { useRate } from "@/hooks/useRate"
import { useToken } from "@/hooks/useToken"
import { useEffect } from "react"

const RatePage = () => {
  const { gameToken } = useToken()
  const { setRank, setTop } = useRate()
  const getRate = async () => {
    setRank(gameToken)
    setTop(gameToken)
  }

  useEffect(() => {
    if (gameToken) getRate()
  }, [gameToken])

  useBackButton()

  return (
    <div className="h-full overflow-hidden">
      <Rate />
      <Menu />
    </div>
  )
}

export default RatePage
