import { getBoosts } from "@/api/boosts"
import { Boost } from "@/types/boosts"
import { create, StoreApi, UseBoundStore } from "zustand"

interface BoostsType {
  boosts: Boost[]
  currentBooster: Boost
  setBoosts: (token: string) => void
  setCurrentBooster: (name: "bomb" | "ballsX2" | "rocket" | "") => void
  setLocalBoosts: () => void
  degreeBoosterCount: (name: "bomb" | "ballsX2" | "rocket" | "") => void
}

export const useBoosts: UseBoundStore<StoreApi<BoostsType>> =
  create<BoostsType>((set) => ({
    boosts: [],
    currentBooster: {
      bought: false,
      quantity: 0,
      disposable: false,
      name: "",
      price: 0,
      currency: ["number"],
    },
    setBoosts: async (token: string) => {
      const data = await getBoosts({ token })
      if (data.code === "success") {
        localStorage.setItem("boosts", JSON.stringify(data.data))
        set(() => ({ boosts: data.data.boosts }))
      }
    },
    setLocalBoosts: () => {
      const dataForParse = localStorage.getItem("top")
      if (dataForParse) {
        const localStorageData = JSON.parse(dataForParse)
        set(() => ({ boosts: localStorageData }))
      }
    },
    setCurrentBooster: (name: "bomb" | "ballsX2" | "rocket" | "") => {
      set((state) => {
        const currentBoost = state.boosts.find((boost) => boost.name === name)
        return {
          currentBooster: currentBoost,
        } as Partial<BoostsType>
      })
    },
    degreeBoosterCount: (name: "bomb" | "ballsX2" | "rocket" | "") => {
      set((state) => {
        const updatedBoosts = state.boosts.map((boost) => {
          if (boost.name === name) {
            boost.quantity -= 1
            return boost
          }
          return boost
        })
        return {
          boosts: updatedBoosts,
        }
      })
    },
  }))
