import Menu from "@/components/common/Menu"
import Game from "@/components/pages/Game"
import { useState } from "react"

const GamePage = () => {
  const [gameModalState, setGameModalState] = useState<
    "empty" | "record" | "start"
  >("start")
  const [score, setScore] = useState(0)

  return (
    <>
      <Game
        gameModalState={gameModalState}
        setGameModalState={setGameModalState}
        score={score}
        setScore={setScore}
      />
      {gameModalState !== "empty" && <Menu />}
    </>
  )
}

export default GamePage
