import { useState } from "react"

import CardWrapper from "@/components/common/CardWrapper"
import { TokensArray } from "@/types/token"
import { formatNumber } from "@/utils/formatNumber"
import { Quest } from "@/types/quests"
import Loader from "@/components/common/Loader"

interface Props {
  loading: boolean
  quest: Quest
  questHandler: (quest: Quest) => Promise<void>
  questId: number
  tokens: TokensArray
}

const QuestItem = ({
  quest,
  questHandler,
  tokens,
  questId,
  loading,
}: Props) => {
  const [error, setError] = useState(false)

  const tokenDecimals = tokens.find(
    (token) => token.id === quest.rewardTokenId
  )?.decimals

  const checkOnTokenForMission = () => {
    const checkOnTokenImage = tokens.find(
      (token) => token.id === quest.rewardTokenId
    )?.image

    return !!(
      quest.reward &&
      checkOnTokenImage &&
      formatNumber(quest.reward, tokenDecimals).fullNumber != 0
    )
  }

  return (
    <button
      onClick={async () => {
        try {
          await questHandler(quest)
        } catch {
          setError(true)
        }
      }}
      className="dark:bg-dark-canvas flex w-full items-center bg-white"
    >
      <CardWrapper
        styles="py-2 items-center"
        bgColor={{ dark: "bg-dark-canvas", light: "white" }}
        image={quest.image}
        size={14}
        square
        imageStyle={`flex items-center rounded-xl w-9 h-9`}
        leftSide={
          <div className="flex-col text-wrap pl-[12px] text-left">
            <div className="flex items-center">
              <h4 className="dark:text-hint-color font-sfUiSemibold text-dark-gray text-[15px]">
                {quest.name}
              </h4>
              {quest?.tasksXUser &&
                quest.tasksXUser.status === "STARTED" &&
                quest.tasksXUser.progressNeeded > 1 &&
                quest.tasksXUser.progress !==
                  quest.tasksXUser.progressNeeded && (
                  <p className="text-hint-color ml-2 text-end text-xs">
                    &#40;{quest.tasksXUser.progress}/
                    {quest.tasksXUser.progressNeeded}&#41;
                  </p>
                )}
            </div>
            <div className="flex items-center">
              <p className="font-sfUiSemibold flex items-center gap-1 text-nowrap text-xs text-black dark:text-white">
                {quest.description}{" "}
              </p>
              <p className="font-sfUiSemibold ml-3 flex items-center gap-1 text-nowrap text-xs text-black dark:text-white">
                {checkOnTokenForMission() &&
                  `+${formatNumber(quest.reward, tokenDecimals).fullNumber}`}
                {checkOnTokenForMission() && (
                  <img
                    className="h-4 w-4 rounded-full"
                    src={
                      tokens &&
                      tokens.find((token) => token.id === quest.rewardTokenId)
                        ?.image
                    }
                  />
                )}
              </p>
            </div>
          </div>
        }
        rightSide={
          <div className="flex items-center justify-between">
            <div className="text-button-color mr-2 rounded-full bg-transparent text-sm font-medium">
              {questId === quest.id ? (
                <>
                  {loading && <Loader />}
                  {quest.tasksXUser?.status &&
                    !error &&
                    !loading &&
                    quest.tasksXUser.status === "COMPLETED" && (
                      <img className="mr-2" src={"/svg/complete.svg"} />
                    )}
                  {!loading &&
                    quest.tasksXUser?.status !== "COMPLETED" &&
                    !error && <img src={"/svg/arrowright.svg"} />}
                  {error && !loading && (
                    <img className="mr-2" src={"/svg/error.svg"} />
                  )}
                </>
              ) : (
                <>
                  {error && <img className="mr-2" src={"/svg/error.svg"} />}
                  {quest.tasksXUser?.status &&
                    !error &&
                    quest.tasksXUser.status === "COMPLETED" && (
                      <img className="mr-2" src={"/svg/complete.svg"} />
                    )}
                  {quest.tasksXUser?.status !== "COMPLETED" && !error && (
                    <img src={"/svg/arrowright.svg"} />
                  )}
                </>
              )}
            </div>
          </div>
        }
      ></CardWrapper>
    </button>
  )
}

export default QuestItem
