import { useBoosts } from "@/hooks/useBoosts"
import { useQuests } from "@/hooks/useQuests"
import { useToken } from "@/hooks/useToken"
import { useTokens } from "@/hooks/useTokens"
import { useUser } from "@/hooks/useUser"
import { useInitData, useLaunchParams } from "@telegram-apps/sdk-react"
import posthog from "posthog-js"
import { ReactNode, useCallback, useEffect } from "react"

const MainLayout = ({ children }: { children: ReactNode }) => {
  const launchParams = useLaunchParams()
  const initData = useInitData()
  const { setTokens } = useTokens()
  const { setQuests } = useQuests()
  const { user } = useUser()
  const { setBoosts } = useBoosts()
  const { token, gameToken } = useToken()

  const fetchData = useCallback(async () => {
    try {
      await setTokens(token)
      await setQuests(token, user.languageCode)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }, [token])

  const fetchGameData = useCallback(async () => {
    try {
      setBoosts(gameToken)
    } catch (error) {
      console.error("Error fetching game data:", error)
    }
  }, [gameToken])

  useEffect(() => {
    if (initData)
      posthog.identify(
        `${user.id}-${import.meta.env.VITE_CORE_API_APP_ID}`,
        {
          applicationId: import.meta.env.VITE_CORE_API_APP_ID,
          telegramId: user.id,
          userId: user.id,
          latestUsername: initData.user?.username,
          latestTgMiniAppPlatform: launchParams.platform,
          latestTgMiniAppVersion: launchParams.version,
        },
        {
          from: initData.startParam,
        }
      )
  }, [user, initData])

  useEffect(() => {
    if (token) void fetchData()
  }, [token])

  useEffect(() => {
    if (gameToken) fetchGameData()
  }, [gameToken])

  return <>{children}</>
}

export default MainLayout
