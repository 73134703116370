import { gameAuth } from "@/api/auth"
import CommonLoader from "@/components/common/CommonLoader"
import { useFriends } from "@/hooks/useFriends"
import { useQuests } from "@/hooks/useQuests"
import { useRate } from "@/hooks/useRate"
import { useToken } from "@/hooks/useToken"
import { useUser } from "@/hooks/useUser"
import { authorization } from "@/utils/authoriztaion"
import { retrieveLaunchParams } from "@telegram-apps/sdk-react"
import { ReactNode, useEffect, useLayoutEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const AuthLayout = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation()
  const { initDataRaw } = retrieveLaunchParams()

  const { token, gameToken, setToken, setGameToken } = useToken()
  const { setUser, setUserAvatar } = useUser()
  const { setLocalQuests } = useQuests()
  const { setLocalFriends } = useFriends()
  const { setLocalRank, setLocalTop } = useRate()

  const [authError, setAuthError] = useState(false)
  const [loading, setLoading] = useState(false)

  const auth = async () => {
    if (initDataRaw) void authorization({ initDataRaw, setToken })
  }

  const gameAuthHandler = async () => {
    try {
      if (initDataRaw) {
        const data = await gameAuth({ initDataRaw })
        if (data.code === "success") {
          setGameToken(data.data.token)
          setUser(data.data.user)
          setLoading(false)
        } else throw new Error()
      }
    } catch (error) {
      setAuthError(true)
      setLoading(false)
      console.log("error", error)
    }
  }

  useEffect(() => {
    if (!token) auth()
    else setUserAvatar(token)
  }, [token])

  useEffect(() => {
    if (!gameToken) {
      setLoading(true)
      gameAuthHandler()
    }
  }, [gameToken])

  useLayoutEffect(() => {
    setLocalQuests()
    // setLocalFriends()
    setLocalRank()
    setLocalTop()
  }, [])

  return (
    <>
      {loading && <CommonLoader />}
      {!authError && !loading && gameToken && children}
      {authError && !loading && (
        <div className="relative">
          <h1 className="font-sfUiBold pt-10 text-2xl">
            {t("errors.authError")}
          </h1>
          <div className="bg-w absolute right-[-20px] top-56 rounded-xl">
            <img src={"/emptylogolight.svg"} />
          </div>
        </div>
      )}
    </>
  )
}

export default AuthLayout
