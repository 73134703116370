import CardWrapper from "@/components/common/CardWrapper"
import { User } from "@/types/user"

interface Props {
  last?: boolean
  rate: number
  self?: boolean
  user: User
}

const RateItem = ({ user, rate, last, self }: Props) => (
  <CardWrapper
    bgColor={{ dark: "bg-dark-canvas", light: "white" }}
    image={user.avatar || user.image || "/svg/default.svg"}
    styles={`${
      rate < 4 &&
      !last &&
      "px-3 py-2 border border-solid border-1 border-text-gray dark:border-modal rounded-xl"
    } ${last && "py-2 "} ${rate > 3 && "py-2"} ${
      !last && rate > 3 && "border-b-[1px] border-text-gray dark:border-modal"
    } ${self && "border border-solid border-1 border-text-gray dark:border-modal px-3  rounded-xl"}`}
    square
    imageStyle="w-9 h-9"
    leftSide={
      <div className="ml-[12px] flex items-center justify-between">
        <div className="flex-col text-left">
          <h4 className="text-gray font-sfUiSemibold text-xs dark:text-white">
            {user.name}
          </h4>
          <div className="flex items-center">
            <div className="mr-2">
              <img width={23} height={14} src="/images/brick.png" />
            </div>
            <p className="font-sfUiRegular text-base text-black dark:text-white">
              {user.score}
            </p>
          </div>
        </div>
      </div>
    }
    rightSide={
      <>
        {rate < 4 && !last && (
          <div
            className={`flex ${rate === 1 && "bg-gold"} ${
              rate === 2 && "bg-silver"
            } ${
              rate === 3 && "bg-bronze"
            } w-[72px] items-center justify-center rounded-full py-1.5`}
          >
            <img
              className="mr-1"
              width={16}
              height={14}
              src="/images/cup.png"
            />
            <p className="text-white">{rate}</p>
          </div>
        )}
        {(rate > 3 || (last && rate !== 0)) && (
          <p className="text-text-gray font-sfUiSemibold text-xl">{rate}</p>
        )}
      </>
    }
  />
)

export default RateItem
