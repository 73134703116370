import { create, StoreApi, UseBoundStore } from "zustand"

import { getTokens } from "@/api/tokens"
import { TokensArray } from "@/types/token"

interface Tokens {
  setLocalTokens: () => void
  setTokens: (token: string) => Promise<void>
  tokens: TokensArray
}

export const useTokens: UseBoundStore<StoreApi<Tokens>> = create<Tokens>(
  (set) => ({
    tokens: [],
    setTokens: async (token: string) => {
      const tokensResponse = await getTokens({ token })
      if (tokensResponse.code === "success") {
        localStorage.setItem(
          "tokens",
          JSON.stringify(
            tokensResponse.data.sort((item) => {
              if (item.symbol === "FUN") return -1
              if (item.symbol === "FUN") return 1
              return 0
            })
          )
        )
        set(() => ({
          tokens: tokensResponse.data.sort((item) => {
            if (item.symbol === "FUN") return -1
            if (item.symbol === "FUN") return 1
            return 0
          }),
        }))
      }
    },
    setLocalTokens: () => {
      const dataForParse = localStorage.getItem("tokens")
      if (dataForParse) {
        const localStorageData = JSON.parse(dataForParse)
        set(() => ({ tokens: localStorageData }))
      }
    },
  })
)
