import { getReferrals } from "@/api/refferals"
import { Friend } from "@/types/user"
import { create, StoreApi, UseBoundStore } from "zustand"

interface FriendsType {
  friends: Friend[]
  setFriends: (token: string) => void
  setLocalFriends: () => void
}

export const useFriends: UseBoundStore<StoreApi<FriendsType>> =
  create<FriendsType>((set) => ({
    friends: [],
    setFriends: async (token: string) => {
      const data = await getReferrals({ token })
      if (data.code === "success")
        if (Array.isArray(data.data)) {
          localStorage.setItem("friends", JSON.stringify(data.data))
          set(() => ({ friends: data.data }))
        }
    },
    setLocalFriends: () => {
      const dataForParse = localStorage.getItem("friends")
      if (dataForParse && Array.isArray(JSON.parse(dataForParse))) {
        const localStorageData = JSON.parse(dataForParse)
        set(() => ({ friends: localStorageData }))
      }
    },
  }))
