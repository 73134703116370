import { requestHandler } from "@/utils/requestHandler"

interface GetParams {
  token: string
}

interface BuyParams {
  byAd?: boolean
  currency: "balance" | "ad" | number
  kind: "boosts"
  token: string
  value: string
}

interface SetParams {
  kind: "boosts"
  token: string
  value: string
}

export const getBoosts = requestHandler<GetParams, any>((params) =>
  fetch(`${import.meta.env.VITE_API}/data/customs/get`, {
    headers: {
      Authorization: `Bearer ${params.token}`,
    },
  })
)

export const buyBoost = requestHandler<BuyParams, any>((params) =>
  fetch(
    `${import.meta.env.VITE_API}/data/customs/buy/${params.kind}/${params.value}?currency=${params.currency}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${params.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        currency: params.currency,
        kind: params.kind,
        value: params.value,
        byAd: params.byAd,
      }),
    }
  )
)

export const setBoost = requestHandler<SetParams, any>((params) =>
  fetch(
    `${import.meta.env.VITE_API}/data/customs/set/${params.kind}/${params.value}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
    }
  )
)
