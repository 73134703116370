import { MutableRefObject } from "react"
import * as PIXI from "pixi.js"
import { Ball, Block } from "@/types"
import { lineRectIntersection } from "./lineIntersection"
import { blockHeight, blockWidth } from "@brick/const"

interface Props {
  aimGraphicsRef: MutableRefObject<PIXI.Graphics | null>
  aimPositionRef: MutableRefObject<{ x: number; y: number } | null>
  ballRef: MutableRefObject<Ball[]>
  blocks: Block[]
  isDraggingRef: MutableRefObject<boolean>
  scale: number
}

export const drawAimTarget = ({
  aimGraphicsRef,
  ballRef,
  isDraggingRef,
  aimPositionRef,
  blocks,
  scale,
}: Props) => {
  if (
    !aimGraphicsRef.current ||
    ballRef.current.length === 0 ||
    !isDraggingRef.current ||
    !aimPositionRef.current
  )
    return

  const g = aimGraphicsRef.current
  g.clear()

  const ball = ballRef.current[0]
  const startX = ball.x
  const startY = ball.y

  const endX = aimPositionRef.current.x
  const endY = aimPositionRef.current.y

  const dx = endX - startX
  const dy = endY - startY
  let angle = Math.atan2(dy, dx)
  let degrees = angle * (180 / Math.PI)

  // Ограничиваем угол в пределах от 15 до 165 градусов
  if (degrees < 15 && degrees >= 0) {
    degrees = -15
    angle = degrees * (Math.PI / 180)
  } else if (degrees > -15 && degrees < 0) {
    degrees = -15
    angle = degrees * (Math.PI / 180)
  } else if (degrees > 165) {
    degrees = -165
    angle = degrees * (Math.PI / 180)
  } else if (degrees < -165) {
    degrees = -165
    angle = degrees * (Math.PI / 180)
  } else if (degrees > 14 && degrees < 90) {
    degrees = -degrees
    angle = degrees * (Math.PI / 180)
  } else if (degrees > 89 && degrees < 166) {
    degrees = -degrees
    angle = degrees * (Math.PI / 180)
  }

  g.lineStyle(2, 0x54a9e9, 1)

  const rayLength = 1000
  let endPointX = startX + rayLength * Math.cos(angle)
  let endPointY = startY + rayLength * Math.sin(angle)

  // Оптимизированная проверка столкновения с блоками
  let collisionPoint = null
  for (const block of blocks) {
    if (!block.visible || (block.bonus && block.visible)) continue
    const blockLeft = block.x * blockWidth * scale
    const blockRight = blockLeft + blockWidth * scale
    const blockTop = block.y * blockHeight * scale
    const blockBottom = blockTop + blockHeight * scale

    const intersection = lineRectIntersection(
      startX,
      startY,
      endPointX,
      endPointY,
      blockLeft,
      blockTop,
      blockRight,
      blockBottom
    )

    if (intersection)
      if (
        !collisionPoint ||
        Math.pow(intersection.x - startX, 2) +
          Math.pow(intersection.y - startY, 2) <
          Math.pow(collisionPoint.x - startX, 2) +
            Math.pow(collisionPoint.y - startY, 2)
      )
        collisionPoint = intersection
  }

  if (collisionPoint) {
    endPointX = collisionPoint.x
    endPointY = collisionPoint.y
  }

  // Рисуем пунктирную линию
  const segmentLength = 10
  const gapLength = 5
  let currentLength = 0
  let isDrawing = true

  g.moveTo(startX, startY)

  while (
    currentLength <
    Math.sqrt(Math.pow(endPointX - startX, 2) + Math.pow(endPointY - startY, 2))
  ) {
    const t =
      currentLength /
      Math.sqrt(
        Math.pow(endPointX - startX, 2) + Math.pow(endPointY - startY, 2)
      )
    const x = startX + t * (endPointX - startX)
    const y = startY + t * (endPointY - startY)

    if (isDrawing) g.lineTo(x, y)
    else g.moveTo(x, y)

    currentLength += isDrawing ? segmentLength : gapLength
    isDrawing = !isDrawing
  }

  if (isDrawing) g.lineTo(endPointX, endPointY)
  else g.moveTo(endPointX, endPointY)
}
