import { Ball, Block } from "@/types"
import { blockHeight, blockWidth } from "@brick/const"
import * as PIXI from "pixi.js"
import React, { MutableRefObject } from "react"

interface Props {
  ballRef: MutableRefObject<Ball[]>
  ballYStartPosition: number
  block: Block
  blockGraphicsRef: MutableRefObject<PIXI.Graphics | null>
  drawBlocks: () => void
  scale: number
  setBallsLength: React.Dispatch<React.SetStateAction<number>>
}

export const dropAnimation = ({
  block,
  ballYStartPosition,
  setBallsLength,
  ballRef,
  scale,
  drawBlocks,
  blockGraphicsRef,
}: Props) => {
  if (!blockGraphicsRef.current) return
  block.visible = false
  drawBlocks()
  const container = blockGraphicsRef.current

  const startY = block.y * blockHeight * scale
  const endY = 11 * blockHeight * scale
  const duration = 400
  const startTime = performance.now()

  const sprite = new PIXI.Graphics()
  sprite.beginFill(0xdff501) // bonusBlockColor
  sprite.drawCircle(0, 0, 6 * scale)
  sprite.endFill()

  const centerX = (block.x * blockWidth + blockWidth / 2) * scale
  sprite.x = centerX
  sprite.y = startY + (blockHeight * scale) / 2

  container.addChild(sprite)

  const animate = (currentTime: number) => {
    const elapsedTime = currentTime - startTime
    const progress = Math.min(elapsedTime / duration, 1)
    const currentY = startY + (endY - startY) * progress

    sprite.y = currentY + (blockHeight * scale) / 2

    if (sprite.y < ballYStartPosition) requestAnimationFrame(animate)
    else {
      // Анимация завершена
      container.removeChild(sprite)
      sprite.destroy()

      setBallsLength((prev) => prev + 1)
      ballRef.current.push({
        x: block.x * (blockWidth * scale) + (blockWidth * scale) / 2,
        y: ballYStartPosition + 1,
        speedX: 0,
        speedY: 0,
        launchFrame: 0,
      })
    }
  }

  requestAnimationFrame(animate)
}
