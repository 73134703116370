import { TokensArray } from "@/types/token"
import { requestHandler } from "@/utils/requestHandler"

interface GetParams {
  token: string
}

export const getTokens = requestHandler<GetParams, TokensArray>((params) =>
  fetch(import.meta.env.VITE_CORE_API_URL + "/tokens/all", {
    headers: {
      Authorization: `Bearer ${params.token}`,
    },
  })
)
