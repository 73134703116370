import { Friend } from "@/types/user"
import { requestHandler } from "@/utils/requestHandler"

export const getReferrals = requestHandler<{ token: string }, Friend[]>(
  (params) =>
    fetch(`${import.meta.env.VITE_API}/data/getReferrals`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
    })
)
