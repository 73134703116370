import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { Container, Graphics, Stage } from "@pixi/react"
import * as PIXI from "pixi.js"
import { Application, Sprite } from "pixi.js"
import { Ball, Block, Fragment } from "@/types"
import {
  ballRadius,
  ballSpeed,
  blockHeight,
  blockWidth,
  width,
} from "@brick/const"
import { useTheme } from "@/hooks/useTheme"
import { drawAimTarget } from "@/utils/Game/drawAimTarget"
import { drawGameBalls } from "@/utils/Game/drawGameBalls"
import { addGameBlocks } from "@/utils/Game/addGameBlocks"
import { moveGameBalls } from "@/utils/Game/moveGameBalls"
import { generateRandomBlocks } from "./generateRandomBlocks"
import { AnimationContext } from "@/context/animationContext"
import BonusBlockContainer from "./GameComponents/BonusBlock"

interface Props {
  ballRef: React.MutableRefObject<Ball[]>
  ballsLength: number
  ballsOptions: { left: number; pushed: boolean }
  blockGraphicsRef: MutableRefObject<PIXI.Graphics | null>
  blockRowsRef: React.MutableRefObject<number>
  blocks: Block[]
  drawBlocks: () => void
  gameModalState: "empty" | "record" | "start"
  gameRestarted: boolean
  gameStarting: boolean
  isShootingRef: MutableRefObject<boolean>
  previousBalls: number
  scale: number
  sendFieldInfo: (block: Block[]) => void
  sendProgressFieldInfo: (directionX: number, directionY: number) => void
  setBallsLength: React.Dispatch<React.SetStateAction<number>>
  setBallsOptions: React.Dispatch<
    React.SetStateAction<{ left: number; pushed: boolean }>
  >
  setBlocks: React.Dispatch<React.SetStateAction<Block[]>>
  setPreviousBalls: React.Dispatch<React.SetStateAction<number>>
  setRequestBombOpened: React.Dispatch<React.SetStateAction<boolean>>
  setScore: React.Dispatch<React.SetStateAction<number>>
  setShowEndModal: React.Dispatch<React.SetStateAction<boolean>>
}

const MyPixiComponent: React.FC<Props> = ({
  ballRef,
  blocks,
  setBlocks,
  blockRowsRef,
  setScore,
  setShowEndModal,
  setBallsLength,
  scale,
  setBallsOptions,
  sendProgressFieldInfo,
  ballsOptions,
  gameModalState,
  gameRestarted,
  setRequestBombOpened,
  isShootingRef,
  drawBlocks,
  blockGraphicsRef,
  sendFieldInfo,
  previousBalls,
  setPreviousBalls,
  gameStarting,
}) => {
  const { theme } = useTheme()
  const [animationOffset, setAnimationOffset] = useState<{ offsetY: number }>({
    offsetY: 0,
  })
  const stageRef = useRef<Stage>(null)
  const frameCountRef = useRef(0)
  const aimPositionRef = useRef<{ x: number; y: number } | null>(null)
  const aimGraphicsRef = useRef<PIXI.Graphics>(null)
  const direction = useRef({ directionX: 0, directionY: 0 })

  const isDraggingRef = useRef<boolean>(false)
  const ballYStartPosition = width * scale - ballRadius * scale - 1
  const ballContainerRef = useRef<PIXI.ParticleContainer<Sprite> | null>(null)
  const fragments = useRef<Fragment[]>([])

  const ballTexture = useMemo(
    () => PIXI.Texture.from("/images/gameBall.png"),
    []
  )

  // Оптимизированная функция drawAim
  const drawAim = useCallback(
    () =>
      drawAimTarget({
        aimGraphicsRef,
        ballRef,
        isDraggingRef,
        aimPositionRef,
        blocks,
        scale,
      }),
    [scale, ballRef, blocks, aimPositionRef, blockWidth, blockHeight]
  )

  const handlePointerDown = (event: React.PointerEvent<HTMLCanvasElement>) => {
    if (ballsOptions.pushed || gameStarting) return
    const rect = event.currentTarget.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top
    aimPositionRef.current = { x, y }
    isDraggingRef.current = true
    drawAim()
  }

  const handlePointerMove = (event: React.PointerEvent<HTMLCanvasElement>) => {
    if (!isDraggingRef.current || gameStarting) return
    const rect = event.currentTarget.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top
    aimPositionRef.current = { x, y }
    drawAim()
  }

  const handlePointerUp = (event: React.PointerEvent<HTMLCanvasElement>) => {
    if (ballsOptions.pushed || !isDraggingRef.current || gameStarting) return

    const rect = event.currentTarget.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    const ball = ballRef.current[0]
    const dx = x - ball.x
    const dy = y - ball.y
    let angle = Math.atan2(dy, dx)
    let degrees = angle * (180 / Math.PI)
    const delay = 100
    const framesPerDelay = Math.floor(delay / (1000 / 60))

    if (degrees < 15 && degrees >= 0) {
      degrees = -15
      angle = degrees * (Math.PI / 180)
    } else if (degrees > -15 && degrees < 0) {
      degrees = -15
      angle = degrees * (Math.PI / 180)
    } else if (degrees > 165) {
      degrees = -165
      angle = degrees * (Math.PI / 180)
    } else if (degrees < -165) {
      degrees = -165
      angle = degrees * (Math.PI / 180)
    } else if (degrees > 14 && degrees < 90) {
      degrees = -degrees
      angle = degrees * (Math.PI / 180)
    } else if (degrees > 89 && degrees < 166) {
      degrees = -degrees
      angle = degrees * (Math.PI / 180)
    }
    const directionXWithoutScale = Math.cos(angle)
    const directionYWithoutScale = Math.sin(angle)
    direction.current = {
      directionX: directionXWithoutScale,
      directionY: directionYWithoutScale,
    }
    console.log(directionXWithoutScale, directionYWithoutScale)
    ballRef.current = ballRef.current.map((ball, index) => ({
      ...ball,
      speedX: ballSpeed * direction.current.directionX * scale,
      speedY: ballSpeed * direction.current.directionY * scale,
      launchFrame: index * framesPerDelay,
    }))

    setBallsOptions((prev) => ({ ...prev, pushed: true }))
    isShootingRef.current = true

    sendProgressFieldInfo(directionXWithoutScale, directionYWithoutScale)
    isDraggingRef.current = false
    aimPositionRef.current = null
    if (aimGraphicsRef.current) aimGraphicsRef.current.clear()

    frameCountRef.current = 0
  }

  useEffect(() => {
    drawAim()
  }, [drawAim])

  const drawBalls = useCallback(
    () =>
      drawGameBalls({
        ballContainerRef,
        ballRef,
        ballTexture,
        scale,
        stageRef: stageRef as MutableRefObject<{
          app: Application<PIXI.ICanvas>
        } | null>,
      }),
    [scale, ballRef, ballTexture]
  )

  const addBlocks = () =>
    addGameBlocks({
      blockRowsRef,
      scale,
      blocks,
      setBlocks,
      drawBlocks,
      isShootingRef,
      setRequestBombOpened,
      setAnimationOffset,
      sendFieldInfo,
    })

  const { startAnimation, stopAnimation } = useMemo(
    () =>
      moveGameBalls({
        ballRef,
        blocks,
        setBallsOptions,
        scale,
        setScore,
        addBlocks,
        drawBalls,
        ballYStartPosition,
        setBallsLength,
        blockGraphicsRef,
        drawBlocks,
        pushed: ballsOptions.pushed,
        isShootingRef,
        previousBalls,
        setPreviousBalls,
        frameCountRef,
      }),
    [addBlocks]
  )

  useEffect(() => {
    if (ballsOptions.pushed) startAnimation()

    return () => stopAnimation()
  }, [ballsOptions.pushed])

  useEffect(() => {
    if (gameModalState === "empty") {
      setBlocks((prev) => (prev = []))
      blockRowsRef.current = 0
      addBlocks()
      drawBalls()
      drawBlocks()
    } else {
      setBlocks(generateRandomBlocks())
      drawBlocks()
      ballRef.current = [
        {
          x: (width / 2) * scale,
          y: width * scale - ballRadius * scale - 1,
          speedX: 0,
          speedY: 0,
          launchFrame: 0,
        },
      ]
      setBallsOptions({
        left: (width * scale) / 2,
        pushed: false,
      })
    }
  }, [gameModalState, gameRestarted])

  useEffect(() => {
    drawBlocks()
    drawBalls()
  }, [drawBlocks, drawBalls])

  return (
    <AnimationContext.Provider value={animationOffset}>
      <Stage
        ref={stageRef}
        width={width * scale}
        height={width * scale}
        options={{
          backgroundColor: theme === "light" ? 0xffffff : 0x2d2d2d,
        }}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
      >
        <Graphics ref={blockGraphicsRef} />
        <Container ref={ballContainerRef} />
        <BonusBlockContainer blocks={blocks} globalScale={scale} />

        <Graphics ref={aimGraphicsRef} />
      </Stage>
    </AnimationContext.Provider>
  )
}

export default React.memo(MyPixiComponent)
