import { requestHandler } from "@/utils/requestHandler"

interface GetParams {
  address?: string
  token: string
  tokenId?: number
}

export const getUsers = requestHandler<GetParams, { image: string }>((params) =>
  fetch(import.meta.env.VITE_CORE_API_URL + "/users", {
    headers: {
      Authorization: `Bearer ${params.token}`,
    },
  })
)
