import React, { ReactNode } from "react"

interface Props {
  buttonContent: ReactNode
  content: ReactNode
  onClose?: () => void
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  showModal: boolean
}

const CommonModal = ({
  showModal,
  setShowModal,
  content,
  buttonContent,
  onClose,
}: Props) => {
  return (
    <>
      {showModal ? (
        <>
          <div
            onClick={() => {
              setShowModal(false)
              if (onClose) onClose()
            }}
            className="fixed inset-0 z-50 flex items-center justify-center outline-none focus:outline-none"
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="relative my-6 max-w-[250px] drop-shadow-2xl"
            >
              {/*content*/}
              <div className="dark:bg-dark-canvas relative flex w-full flex-col rounded-xl border-0 bg-white p-5 outline-none drop-shadow-lg focus:outline-none">
                {/*body*/}
                {onClose && (
                  <i
                    onClick={() => {
                      setShowModal(false)
                      onClose()
                    }}
                    className="ml-auto"
                  >
                    <img src="/svg/close.svg" />
                  </i>
                )}
                <div className="relative flex-col items-center justify-center">
                  {content}
                </div>
                {/*footer*/}
                {buttonContent}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default CommonModal
