interface Props {
  height?: number
  image: string | undefined
  size?: number
  styles?: string
  width?: number
}

const Logo = ({ size, image, width, height, styles }: Props) => (
  <img
    src={image}
    className={`w-${width || size} h-${height || size} ${styles} rounded-full`}
  />
)

export default Logo
