import CommonHeader from "@/components/common/CommonHeader"
import QuestItem from "./QuestsItem"
import { useTranslation } from "react-i18next"
import SubHeader from "@/components/common/SubHeader"
import { postQuest } from "@/api/quests"
import { useToken } from "@/hooks/useToken"
import { useQuests } from "@/hooks/useQuests"
import { useTokens } from "@/hooks/useTokens"
import { useState } from "react"
import { initUtils } from "@telegram-apps/sdk-react"
import { PostQuestResponse, Quest } from "@/types/quests"
import { SuccessResponse } from "@/utils/requestHandler"
import useInterval from "@/hooks/useInterval"
import DailyQuest from "./DailyQuest"
import { useUser } from "@/hooks/useUser"

const utils = initUtils()

const Quests = () => {
  const { t } = useTranslation()
  const { token } = useToken()
  const { user } = useUser()
  const { quests, setQuestAction } = useQuests()
  const { tokens } = useTokens()
  const [clicked, setClicked] = useState(false)
  const [requestTimes, setRequestTimes] = useState(0)
  const [loading, setLoading] = useState(false)
  const [questId, setQuestId] = useState<number>(0)

  const questClickHandler = async (quest: Quest) => {
    if (quest.tasksXUser?.status === "COMPLETED") return
    const navigate = () => {
      if (quest.insideTg) utils.openTelegramLink(quest.link)
      else utils.openLink(quest.link)
    }
    setLoading(true)
    setQuestId(quest.id)

    await Promise.allSettled([
      postQuest({ token, taskId: quest.id, language: user.languageCode }),
      navigate(),
    ])
      .then((results) => {
        results.forEach((result, index) => {
          if (result.status === "fulfilled" && index === 0)
            if (
              (result.value as SuccessResponse<PostQuestResponse>).code ===
              "success"
            ) {
              const updateQuests = quests.map((quest) => {
                if (
                  quest.id ===
                  (result.value as SuccessResponse<PostQuestResponse>).data
                    .taskId
                ) {
                  quest.tasksXUser = (
                    result.value as SuccessResponse<PostQuestResponse>
                  ).data
                  return quest
                }
                return quest
              })
              setLoading(false)
              setQuestAction(updateQuests)
            }
        })
      })
      .catch((e: string) => {
        if (e) throw new Error(e)
        setLoading(false)
      })
    if (quest.action.type === "SUBSCRIBE") setClicked(true)
    else setLoading(false)
  }

  const checkOnQuestsEmpty = (missionsType: string) => {
    if (missionsType === "follow") {
      const isExisting = quests.find((quest) => {
        if (
          quest.action &&
          (quest.action.type.includes("FOLLOW_URL") ||
            quest.action.type.includes("SUBSCRIBE"))
        )
          return quest
      })

      return !!isExisting
    } else {
      const isExisting = quests.find((quest) => {
        if (
          !quest.action ||
          (!quest.action.type.includes("FOLLOW_URL") &&
            !quest.action.type.includes("SUBSCRIBE"))
        )
          return quest
      })

      return !!isExisting
    }
  }

  const postPolling = async () => {
    if (clicked) {
      setRequestTimes((prev) => prev + 1)

      if (requestTimes > 0 && requestTimes < 11) {
        setLoading(true)
        const actionQuestResponses = await postQuest({
          token,
          taskId: questId,
          language: user.languageCode,
        })

        if (actionQuestResponses.code === "success") {
          const updateQuests = quests.map((quest) => {
            if (quest.id === actionQuestResponses.data.taskId) {
              quest.tasksXUser = actionQuestResponses.data
              return quest
            }
            return quest
          })

          if (actionQuestResponses.data.status === "COMPLETED") {
            setClicked(false)
            setQuestAction(updateQuests)
            setLoading(false)
          }
        }
      } else setLoading(false)
    }
  }

  useInterval(() => postPolling(), 3000)

  return (
    <div className="w-full flex-col">
      <DailyQuest />
      <CommonHeader title={t("questsPage.playMissions")} styles="mb-3" />
      {checkOnQuestsEmpty("play") ? (
        <div className="dark:bg-dark-canvas mb-1 rounded-xl bg-white px-2">
          {quests.map((quest) => {
            if (
              !quest.action ||
              (!quest.action.type.includes("FOLLOW_URL") &&
                !quest.action.type.includes("SUBSCRIBE"))
            )
              return (
                <QuestItem
                  key={quest.id}
                  quest={quest}
                  questHandler={questClickHandler}
                  tokens={tokens}
                  questId={questId}
                  loading={loading}
                />
              )
          })}
        </div>
      ) : (
        <SubHeader
          styles="text-center px-3"
          title={t("questsPage.noPlayMissions")}
        />
      )}
      <CommonHeader title={t("questsPage.followMissions")} styles="mb-3" />
      {checkOnQuestsEmpty("follow") ? (
        <div className="dark:bg-dark-canvas mb-5 rounded-xl bg-white px-2">
          {quests.map((quest) => {
            if (
              quest.action &&
              (quest.action.type.includes("FOLLOW_URL") ||
                quest.action.type.includes("SUBSCRIBE"))
            )
              return (
                <QuestItem
                  key={quest.id}
                  quest={quest}
                  questHandler={questClickHandler}
                  tokens={tokens}
                  questId={questId}
                  loading={loading}
                />
              )
          })}
        </div>
      ) : (
        <SubHeader
          styles="text-center px-3 mb-3"
          title={t("questsPage.noFollowMissions")}
        />
      )}
    </div>
  )
}

export default Quests
