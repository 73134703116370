import { createBrowserRouter } from "react-router-dom"
import FriendsPage from "./pages/FriendsPage"
import QuestsPage from "./pages/QuestsPage"
import RatePage from "./pages/RatePage"
import ProfilePage from "./pages/ProfilePage"
import BoostsPage from "./pages/BoostsPage"
import GamePage from "./pages/GamePage"

const routes = createBrowserRouter([
  {
    path: "/",
    element: <GamePage />,
  },
  {
    path: "/game",
    element: <GamePage />,
  },
  {
    path: "/friends",
    element: <FriendsPage />,
  },
  {
    path: "/quests",
    element: <QuestsPage />,
  },
  {
    path: "/rate",
    element: <RatePage />,
  },
  {
    path: "/profile",
    element: <ProfilePage />,
  },
  {
    path: "/boosts",
    element: <BoostsPage />,
  },
])

export default routes
