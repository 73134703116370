import { useUser } from "@/hooks/useUser"
import { useInitData } from "@telegram-apps/sdk-react"
import { ReactNode, useEffect } from "react"
import { useTranslation } from "react-i18next"

const LocaleLayout = ({ children }: { children: ReactNode }) => {
  const initData = useInitData()
  const { i18n } = useTranslation()

  const { user } = useUser()

  const checkOnLanguage = () => {
    void i18n.changeLanguage(
      ["ru", "uz", "uk"].includes(user.languageCode) ? "ru" : "en"
    )
  }

  useEffect(() => {
    checkOnLanguage()
  }, [])

  return <>{children}</>
}

export default LocaleLayout
