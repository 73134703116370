import Menu from "@/components/common/Menu"
import Quests from "@/components/pages/Quests"
import useBackButton from "@/hooks/useBackButton"
import { useQuests } from "@/hooks/useQuests"
import { useToken } from "@/hooks/useToken"
import { useUser } from "@/hooks/useUser"
import { useEffect } from "react"

const QuestsPage = () => {
  const { setQuests, setQuestsByUser, quests } = useQuests()
  const { user } = useUser()
  const { token, gameToken } = useToken()

  const getQuests = async () => {
    await setQuests(token, user.languageCode)

    await setQuestsByUser(token, user.languageCode)
  }

  useEffect(() => {
    if (token) void getQuests()
  }, [token])

  useBackButton()

  return (
    <div className="h-screen w-full px-3 pb-3 pt-3">
      <Quests />
      <Menu />
    </div>
  )
}

export default QuestsPage
