import React, { MutableRefObject } from "react"
import { addBonusBlocks } from "./addBonusBlock"
import { Block } from "@/types"

interface Props {
  blockRowsRef: MutableRefObject<number>
  blocks: Block[]
  setBlocks: React.Dispatch<React.SetStateAction<Block[]>>
  drawBlocks: () => void
  isShootingRef: MutableRefObject<boolean>
  scale: number
  setAnimationOffset: React.Dispatch<React.SetStateAction<{ offsetY: number }>>
  setRequestBombOpened: React.Dispatch<React.SetStateAction<boolean>>
  sendFieldInfo: (blocks: Block[]) => void
}

export const addGameBlocks = ({
  blockRowsRef,
  scale,
  blocks,
  setBlocks,
  drawBlocks,
  isShootingRef,
  setRequestBombOpened,
  setAnimationOffset,
  sendFieldInfo,
}: Props) => {
  blockRowsRef.current += 1

  if (blocks.length > 36) {
    const lastElements = blocks.slice(0, 6)
    const checkOnEmpty = lastElements.find((block) => block.visible)
    if (!checkOnEmpty) setBlocks(blocks.slice(6))
    else if (!isShootingRef.current) {
      setRequestBombOpened(true)
      return
    }
  }

  const newBlocks = addBonusBlocks(blockRowsRef, scale)
  setBlocks((prev) => [...prev, ...newBlocks])
  if (blockRowsRef.current === 1) {
    sendFieldInfo(newBlocks)
  }

  const animateBlocks = (time: number) => {
    const duration = 200
    const progress = Math.min((time - startTime) / duration, 1)
    const currentOffset = progress

    setBlocks((prev) =>
      prev.map((block) => {
        // Если начальная позиция не установлена, устанавливаем ее
        if (block.initialY === undefined) block.initialY = block.y

        // Анимируем движение от начальной позиции до начальная позиция + 1
        block.y = block.initialY + progress
        return block
      })
    )
    setAnimationOffset({ offsetY: currentOffset })

    if (progress < 1) {
      drawBlocks()
      requestAnimationFrame(animateBlocks)
    } else {
      // После завершения анимации, устанавливаем точную конечную позицию
      // и удаляем временное свойство initialY
      setBlocks((prev) =>
        prev.map((block) => {
          if (block.initialY) block.y = Math.round(block.initialY + 1)
          delete block.initialY
          return block
        })
      )
      drawBlocks()
    }
  }

  const startTime = performance.now()
  animateBlocks(startTime)
}
