import CommonHeader from "@/components/common/CommonHeader"
import { useTranslation } from "react-i18next"
import FriendsItem from "./FriendsItem"
import SubHeader from "@/components/common/SubHeader"
import { useFriends } from "@/hooks/useFriends"
import { referrerBonus, referrerPremiumBonus } from "@brick/common"

const Friends = () => {
  const { t } = useTranslation()
  const { friends } = useFriends()

  return (
    <div className="w-full px-3">
      <div className="dark:bg-dark-canvas mb-3 mt-3 rounded-xl bg-white p-3">
        <div className="flex">
          <div className="mr-[12px] h-[42px] w-[42px]">
            <img width={42} height={42} src="/images/inviteFriend.png" />
          </div>
          <div>
            <CommonHeader
              styles="text-base font-sfUiSemibold"
              title={t("friendsPage.inviteFriend")}
            />
            <div className="flex items-center">
              <img src="/svg/coin.svg" width={16} height={16} />
              <p className="font-sfUiSemibold mx-1 text-sm text-black dark:text-white">
                +{referrerBonus}
              </p>
              <p className="text-gray font-sfUiSemibold text-sm">
                {t("friendsPage.forYouAndFriend")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="dark:bg-dark-canvas mb-5 rounded-xl bg-white p-3">
        <div className="flex items-center">
          <div className="mr-[12px] h-[42px] w-[42px]">
            <img width={42} height={42} src="/images/inviteFriendPremium.png" />
          </div>
          <div>
            <CommonHeader
              styles="text-base font-sfUiSemibold"
              title={t("friendsPage.inviteFriendPremium")}
            />
            <div className="flex items-center">
              <img src="/svg/coin.svg" width={16} height={16} />
              <p className="font-sfUiSemibold mx-1 text-sm text-black dark:text-white">
                +{referrerPremiumBonus}
              </p>
              <p className="text-gray font-sfUiSemibold text-sm">
                {t("friendsPage.forYouAndFriend")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <CommonHeader
        title={t("friendsPage.yourFriends")}
        styles="mb-3 flex justify-start items-center"
      >
        <p className="text-gray font-sfUiBold ml-1 align-middle text-2xl text-black dark:text-white">
          {" "}
          &#40;{friends ? friends.length : 0}&#41;
        </p>
      </CommonHeader>
      <div className="relative flex-col">
        {(!friends || friends.length === 0) && (
          <div className="absolute top-1">
            <SubHeader
              styles="text-center"
              title={t("friendsPage.emptyFriends")}
            />
          </div>
        )}
        {friends &&
          friends.length !== 0 &&
          friends.map((friend) => (
            <FriendsItem key={friend.id} friend={friend} />
          ))}
      </div>
      {friends && friends.length !== 0 && (
        <SubHeader
          styles="text-center text-base"
          title={t("friendsPage.inviteFriendGetBonus")}
        />
      )}
      {friends.length > 4 && <div className="h-[176px]"></div>}
    </div>
  )
}

export default Friends
