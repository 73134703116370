import { create, StoreApi, UseBoundStore } from "zustand"

import { User } from "@/types/user"
import { getUserInfo } from "@/api/user"
import { getUsers } from "@/api/users"

interface UserType {
  setLocalUser: () => void
  setUser: (user: User) => void
  setUserAvatar: (token: string) => void
  updateUserBalance: (id: number | undefined, token: string) => void
  user: User
}

export const useUser: UseBoundStore<StoreApi<UserType>> = create<UserType>(
  (set) => ({
    user: {
      alive: false,
      avatar: "",
      score: 0,
      balance: 0,
      ban: false,
      country: "",
      dignities: [],
      from: "",
      id: 0,
      lang: 0,
      languageCode: "",
      lastActivity: "",
      lastGameAt: "",
      lastNotifiedAt: "",
      name: "",
      openedMiniApp: false,
      touchedAfterNotification: false,
      username: "",
      image: "",
    },
    setUser: (user: User) => set(() => ({ user: user })),
    updateUserBalance: async (id: number | undefined, token: string) => {
      const updateUserResponse = await getUserInfo({ id, token })
      if (updateUserResponse.code === "success")
        set((state) => ({
          user: {
            ...state.user,
            balance: updateUserResponse.data.balance,
          },
        }))
    },
    setUserAvatar: async (token: string) => {
      const updateUserResponse = await getUsers({ token })
      if (updateUserResponse.code === "success")
        set((state) => ({
          user: {
            ...state.user,
            avatar: updateUserResponse.data.image,
          },
        }))
    },
    setLocalUser: () => {
      const dataForParse = localStorage.getItem("user")
      if (dataForParse) {
        const localStorageData = JSON.parse(dataForParse)
        set(() => ({ user: localStorageData }))
      }
    },
  })
)
