import { AdController, ShowPromiseResult } from "@/global"
import { useCallback, useEffect, useRef } from "react"

/**
 * Check Typescript section
 * and use your path to adsgram types
 */

export interface useAdsgramParams {
  onError?: (result: ShowPromiseResult) => void
  onReward: () => void
}

export function useAdsgram({
  onReward,
  onError,
}: useAdsgramParams): () => Promise<void> {
  const AdControllerRef = useRef<AdController | undefined>(undefined)

  useEffect(() => {
    AdControllerRef.current = window.Adsgram?.init({
      blockId: "1827",
    })
  }, [])

  return useCallback(async () => {
    if (AdControllerRef.current)
      AdControllerRef.current
        .show()
        .then(() => {
          // user watch ad till the end
          onReward()
        })
        .catch((result: ShowPromiseResult) => {
          // user get error during playing ad or skip ad
          onError?.(result)
        })
    else
      onError?.({
        error: true,
        done: false,
        state: "load",
        description: "Adsgram script not loaded",
      })
  }, [onError, onReward])
}
