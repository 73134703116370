import { Block } from "@/types"
import { MutableRefObject } from "react"
import { minVisibleBlocks } from "@brick/game"

export function addBonusBlocks(
  blockRowsRef: MutableRefObject<number>,
  scale: number
) {
  const array: Block[] = []
  let bonusAdded = false
  let numVisibleBlocks = 0

  for (let col = 0; col < 6; col++) {
    const block: Block = {
      x: col,
      y: 0,
      visible: true,
      strength: blockRowsRef.current,
      bonus: false,
    }
    const random = Math.random() < 0.5
    if (!bonusAdded && Math.random() < 0.3) {
      block.x = col
      block.y = 0
      block.strength = 1
      block.bonus = true
      block.visible = true
      bonusAdded = true
    } else {
      block.visible = random
      block.strength = random ? blockRowsRef.current : 0
    }

    if (block.visible) {
      numVisibleBlocks++
    }

    array.push(block)
  }

  if (!bonusAdded || numVisibleBlocks < minVisibleBlocks + 1)
    return addBonusBlocks(blockRowsRef, scale)

  return array
}
