type BaseRequest<T, V> = (
  params: T
) => Promise<Response & { json: () => Promise<V> }>

export interface SuccessResponse<V> {
  code: "success"
  data: V
}

interface ErrorResponse<E = Error> {
  code: "error"
  error: E
}

export type BaseResponse<V, E> = Promise<SuccessResponse<V> | ErrorResponse<E>>

export const requestHandler =
  <T, V, E = Error>(request: BaseRequest<T, V>) =>
  async (params: T): BaseResponse<V, E> => {
    try {
      const response = await request(params)
      if (response.ok) {
        const { data } = await response.clone().json()
        if (data) return { code: "success", data }
        else {
          const data = await response.json()
          return { code: "success", data }
        }
      } else throw new Error()
    } catch (e) {
      console.log(e)
      return { code: "error", error: e as E }
    }
  }
