import { blockHeight, blockWidth } from "@brick/const"
import { MutableRefObject } from "react"
import * as PIXI from "pixi.js"
import { Block } from "@/types"

interface Props {
  blockGraphicsRef: MutableRefObject<PIXI.Graphics | null>
  blocks: Block[]
  maxColor: { r: number; g: number; b: number }
  minColor: { r: number; g: number; b: number }
  scale: number
  textContainerRef: MutableRefObject<PIXI.Container | null>
}

export const drawGameBlocks = ({
  blockGraphicsRef,
  textContainerRef,
  blocks,
  minColor,
  maxColor,
  scale,
}: Props) => {
  if (!blockGraphicsRef.current) return

  const g = blockGraphicsRef.current

  g.clear()
  if (textContainerRef.current) textContainerRef.current.removeChildren()
  else {
    // Создаем новый текстовый контейнер, если его еще нет
    textContainerRef.current = new PIXI.Container()
    g.addChild(textContainerRef.current)
  }
  blocks
    .filter((block) => block.visible && !block.bonus)
    .forEach((block) => {
      const t = (block.strength - 1) / 2
      const r = Math.round(minColor.r + (maxColor.r - minColor.r) * t)
      const gb = Math.round(minColor.g + (maxColor.g - minColor.g) * t)
      const b = Math.round(minColor.b + (maxColor.b - minColor.b) * t)

      g.beginFill([r / 255, gb / 255, b / 255])
      g.lineStyle(0)
      g.drawRoundedRect(
        block.x * blockWidth * scale,
        block.y * blockHeight * scale,
        blockWidth * scale,
        blockHeight * scale,
        6 * scale
      )
      g.endFill()

      const textStyle = new PIXI.TextStyle({
        fontFamily: "Arial",
        fontSize: 14 * scale,
        fill: 0xffffff,
        align: "center",
      })

      const text = new PIXI.Text(block.strength.toString(), textStyle)
      text.x =
        block.x * blockWidth * scale + (blockWidth * scale) / 2 - text.width / 2
      text.y =
        block.y * blockHeight * scale +
        (blockHeight * scale) / 2 -
        text.height / 2
      // Добавляем текст в текстовый контейнер вместо графики
      textContainerRef.current!.addChild(text)
    })
}
