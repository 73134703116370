import { User } from "@/types/user"
import { width } from "@brick/const"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  gameModalState: "empty" | "record" | "start"
  scale: number
  setBallsOptions: React.Dispatch<
    React.SetStateAction<{
      left: number
      pushed: boolean
    }>
  >
  setGameModalState: React.Dispatch<
    React.SetStateAction<"empty" | "record" | "start">
  >
  user: User
}

const GameModal = ({
  gameModalState,
  setGameModalState,
  user,
  setBallsOptions,
  scale,
}: Props) => {
  const { t } = useTranslation()
  const [random] = useState(Math.floor(Math.random() * 4))

  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 z-10 flex items-center justify-center backdrop-blur-[2px]">
      {gameModalState === "record" && (
        <div className="w-full flex-col items-center justify-center px-6">
          <div className="m-auto flex items-end justify-center">
            <div className="mb-3 mr-2 h-[14px] w-[23px]">
              <img src="/images/brick.png" width={23} height={14} />{" "}
            </div>
            <h2 className="font-sfUiBold text-[64px] leading-[64px]">
              {user.score}
            </h2>
          </div>
          <p className="text-gray font-sfUiRegular text-center text-base">
            {t("gameModal.yourRecord")}
          </p>
          <p className="font-sfUiRegular my-3 text-center text-base text-white">
            {t(`game.welcomeText.${random}`)}
          </p>
          <button
            className="bg-button-color font-sfUiBold mb-2 w-full rounded-full py-3 text-white"
            onClick={() => {
              setGameModalState("empty")
              setBallsOptions({
                left: (width * scale) / 2,
                pushed: false,
              })
            }}
          >
            {t("gameModal.startGame")}
          </button>
          <p className="text-gray font-sfUiRegular px-2 text-center text-sm">
            {t("gameModal.yourAdventureStartsHere")}
          </p>
        </div>
      )}
      {gameModalState === "start" && (
        <div className="w-full flex-col items-center justify-center px-6">
          <div className="m-auto h-[153px] w-[153px]">
            <img src="/images/gameBig.png" width={153} height={153} />
          </div>

          <p className="font-sfUiSemibold my-3 px-4 text-center text-base text-black dark:text-white">
            {t("gameModal.welcomeBlockmaster")}
          </p>
          <button
            className="bg-button-color font-sfUiBold mb-2 w-full rounded-full py-3 text-white"
            onClick={() => {
              setGameModalState("empty")
            }}
          >
            {t("gameModal.startGame")}
          </button>
        </div>
      )}
    </div>
  )
}

export default GameModal
