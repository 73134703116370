import { Block } from "@/types"
import { blockHeight, blockWidth } from "@brick/const"
import * as PIXI from "pixi.js"

interface Props {
  block: Block
  blockGraphicsRef: PIXI.Graphics
  scale: number
}

export function animateFragments({ block, scale, blockGraphicsRef }: Props) {
  const FRAGMENT_COUNT = 25
  const GRAVITY = 0.5
  const HORIZONTAL_SPREAD = 2

  const fragmentWidth = (blockWidth * scale) / 5
  const fragmentHeight = (blockHeight * scale) / 5

  for (let i = 0; i < FRAGMENT_COUNT; i++) {
    const fragment = new PIXI.Graphics()
    fragment.beginFill(0xffa078)
    fragment.drawRect(0, 0, fragmentWidth, fragmentHeight)
    fragment.endFill()

    const row = Math.floor(i / 5)
    const col = i % 5

    fragment.x = block.x * blockWidth * scale + col * fragmentWidth
    fragment.y = block.y * blockHeight * scale + row * fragmentHeight

    const speedX = (Math.random() - 0.5) * HORIZONTAL_SPREAD
    let speedY = -Math.random() * 5 // Начальная скорость вверх для эффекта подпрыгивания

    blockGraphicsRef.addChild(fragment)

    // Анимация каждого фрагмента
    function animateFragment() {
      fragment.x += speedX
      fragment.y += speedY
      speedY += GRAVITY

      if (fragment.y > window.innerHeight) {
        blockGraphicsRef.removeChild(fragment)
        return
      }

      // Удаление фрагмента, когда он выходит за пределы экрана
      if (
        fragment.y > window.innerHeight ||
        fragment.x < 0 ||
        fragment.x > window.innerWidth
      )
        blockGraphicsRef.removeChild(fragment)
      else requestAnimationFrame(animateFragment)
    }

    animateFragment()
  }
}
