import {
  DailyQuest,
  PostQuestResponse,
  QuestsArray,
  QuestsByUserArray,
} from "@/types/quests"
import { DefaultHeaders } from "@/utils/headers"
import { requestHandler } from "@/utils/requestHandler"

interface GetParams {
  token: string
  language: string
}

interface QuestProps {
  taskId: number
  token: string
  language: string
}

export const getQuests = requestHandler<GetParams, QuestsArray>((params) =>
  fetch(import.meta.env.VITE_CORE_API_URL + "/tasks/all", {
    headers: DefaultHeaders({ token: params.token, language: params.language }),
  })
)

export const getQuestsByUser = requestHandler<GetParams, QuestsByUserArray>(
  (params) =>
    fetch(import.meta.env.VITE_CORE_API_URL + "/tasks/all-by-user", {
      headers: DefaultHeaders({
        token: params.token,
        language: params.language,
      }),
    })
)

export const postQuest = requestHandler<QuestProps, PostQuestResponse>(
  (params) =>
    fetch(
      import.meta.env.VITE_CORE_API_URL + `/tasks/task-status/${params.taskId}`,
      {
        method: "POST",
        headers: DefaultHeaders({
          token: params.token,
          language: params.language,
        }),
      }
    )
)

export const getQuestAction = requestHandler<QuestProps, PostQuestResponse>(
  (params) =>
    fetch(
      import.meta.env.VITE_CORE_API_URL + `/tasks/task-status/${params.taskId}`,
      {
        headers: DefaultHeaders({
          token: params.token,
          language: params.language,
        }),
      }
    )
)

export const getDailyBonus = async ({
  token,
}: {
  token: string
}): Promise<{
  code: "success" | "error"
  data: DailyQuest
}> => {
  try {
    const request = await fetch(`${import.meta.env.VITE_API}/data/dailyBonus`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const res = await request.json()
    return {
      code: "success",
      data: res,
    }
  } catch (error) {
    return {
      code: "error",
      data: {
        coins: 0,
        diff: 0,
      },
    }
  }
}

export const postDailyQuest = async ({
  token,
}: {
  token: string
}): Promise<{
  code: "success" | "error"
  data: { coins: number }
}> => {
  try {
    const request = await fetch(`${import.meta.env.VITE_API}/data/dailyBonus`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const res = await request.json()
    return {
      code: "success",
      data: res,
    }
  } catch (error) {
    return {
      code: "error",
      data: {
        coins: 0,
      },
    }
  }
}
