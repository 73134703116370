export const formatNumber = (
  numberParam: number | string | undefined,
  decimals = 9,
  replace?: boolean
): {
  fullNumber: number | string
  truncatedNumber: number | string
  lastTwoDigits: number | string
} => {
  let number: number
  if (typeof numberParam === "string")
    number =
      decimals === 0
        ? parseFloat(numberParam)
        : parseFloat(numberParam) / Math.pow(10, decimals)
  else if (typeof numberParam === "number")
    number = decimals === 0 ? numberParam : numberParam / Math.pow(10, decimals)
  else
    return {
      fullNumber: 0,
      truncatedNumber: 0,
      lastTwoDigits: 0,
    }

  const numberString = !replace
    ? number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, "\u2009")
    : number.toFixed(2)

  const truncatedNumber = numberString.slice(0, -3)
  const lastTwoDigits = numberString.slice(-2)

  let fullNumber
  if (lastTwoDigits === "00") fullNumber = truncatedNumber
  else fullNumber = numberString

  return {
    fullNumber,
    truncatedNumber,
    lastTwoDigits,
  }
}
