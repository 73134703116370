import { postGameStart } from "@/api/game"
import { Block } from "@/types"
import { Game } from "@/types/game"
import { create, StoreApi, UseBoundStore } from "zustand"

interface GameType {
  game: Game
  setGame: (token: string, gameField: Block[]) => void
}

export const useGame: UseBoundStore<StoreApi<GameType>> = create<GameType>(
  (set) => ({
    game: {
      ballsCount: 0,
      field: [],
      id: "",
      status: 0,
      userId: 0,
    },
    setGame: async (token: string, gameField) => {
      const data = await postGameStart({
        token,
        gameField,
      })
      if (data.code === "success") {
        set(() => ({ game: data.data }))
      }
    },
  })
)
