import React, { ReactNode, useCallback, useEffect } from "react"
import {
  useClosingBehavior,
  useMiniApp,
  useSwipeBehavior,
  useThemeParams,
  useViewport,
} from "@telegram-apps/sdk-react"
import { useTheme } from "@/hooks/useTheme"
import "../App.css"

interface ThemeLayoutProps {
  children: ReactNode
}

const ThemeLayout: React.FC<ThemeLayoutProps> = ({ children }) => {
  const closingBehavior = useClosingBehavior()
  const swipeBehavior = useSwipeBehavior()
  const viewport = useViewport()
  const miniApp = useMiniApp()
  const themeParams = useThemeParams()
  const { setTheme } = useTheme()

  const applyTheme = useCallback(() => {
    const root = document.documentElement
    const isDark = themeParams.isDark

    root.classList.toggle("dark", isDark)
    root.classList.toggle("light", !isDark)

    miniApp.setHeaderColor(isDark ? "#171717" : "#F4F4F4")
    setTheme(isDark ? "dark" : "light")
  }, [themeParams, miniApp, setTheme])

  const setupViewport = useCallback(() => {
    closingBehavior.enableConfirmation()
    swipeBehavior.disableVerticalSwipe()
    viewport?.expand()
  }, [closingBehavior, swipeBehavior, viewport])

  useEffect(() => {
    applyTheme()
    setupViewport()

    themeParams.on("change", applyTheme)
    viewport?.on("change:isExpanded", setupViewport)

    return () => {
      themeParams.off("change", applyTheme)
      viewport?.off("change:isExpanded", setupViewport)
    }
  }, [applyTheme, setupViewport, themeParams, viewport])

  return (
    <div className="root bg-light-bg-color dark:bg-bg-color relative h-screen w-full text-black dark:text-white">
      {children}
    </div>
  )
}

export default ThemeLayout
