import React from "react"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"
import routes from "./routes"
import { RouterProvider } from "react-router-dom"

const App = () => {
  return (
    <PostHogProvider client={posthog}>
      <RouterProvider router={routes} />
    </PostHogProvider>
  )
}

export default App
