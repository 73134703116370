import { Block } from "@/types"

export const generateRandomBlocks = () => {
  const blocks: Block[] = []
  for (let i = 0; i < 6; i++) {
    const start = i * 6
    const end = start + 6

    let visibleCount = 0
    const bonusIndex = Math.floor(Math.random() * 6)

    for (let j = 0; j < 6; j++) {
      const isBonus = j === bonusIndex
      const isVisible =
        isBonus || (visibleCount < 3 && Math.random() < 0.5) || visibleCount > 5

      blocks[start + j] = {
        x: (start + j) % 6, // Примерный расчет x
        y: Math.floor((start + j) / 6), // Примерный расчет y
        visible: isVisible,
        bonus: isBonus,
        strength: i, // Случайная сила от 0 до 99
      }

      if (isVisible) {
        visibleCount++
      }
    }
  }
  return blocks
}
