interface Props {
  styles?: string
  title: string
}

const SubHeader = ({ title, styles }: Props) => {
  return (
    <div className="text-hint-color text-left text-base">
      <h3 className={`${styles}`}>{title}</h3>
    </div>
  )
}

export default SubHeader
