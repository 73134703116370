import { Ball } from "@/types"
import { MutableRefObject } from "react"
import * as PIXI from "pixi.js"
import { type Application } from "pixi.js"
import { ballRadius } from "@brick/const"

interface Props {
  ballContainerRef: MutableRefObject<PIXI.Container | null>
  ballRef: MutableRefObject<Ball[]>
  ballTexture: PIXI.Texture
  scale: number
  stageRef: MutableRefObject<{ app: Application<PIXI.ICanvas> } | null>
}

const spritePool: PIXI.Sprite[] = []
const trailPool: PIXI.Sprite[] = []
const TRAIL_LENGTH = 15
const TRAIL_INITIAL_COLOR = 0x54a9e9

// Создаем отдельный контейнер для трейлов
const trailContainer = new PIXI.ParticleContainer()

export const drawGameBalls = ({
  ballContainerRef,
  ballRef,
  ballTexture,
  scale,
  stageRef,
}: Props) => {
  if (!ballContainerRef.current) return

  const container = ballContainerRef.current

  // Добавляем trailContainer в сцену, если еще не добавлен
  if (
    stageRef.current &&
    !stageRef.current.app.stage.children.includes(trailContainer)
  )
    stageRef.current.app.stage.addChildAt(trailContainer, 0) // Добавляем перед остальными объектами

  // Удаляем лишние спрайты
  while (container.children.length > ballRef.current.length) {
    const sprite = container.removeChildAt(
      container.children.length - 1
    ) as PIXI.Sprite
    spritePool.push(sprite)
  }

  // Добавляем или обновляем спрайты и хвосты
  ballRef.current.forEach((ball, index) => {
    let sprite: PIXI.Sprite
    if (index < container.children.length)
      sprite = container.children[index] as PIXI.Sprite
    else {
      sprite = spritePool.pop() || new PIXI.Sprite(ballTexture)
      container.addChild(sprite)
    }
    sprite.x = ball.x
    sprite.y = ball.y
    sprite.width = ballRadius * scale * 2
    sprite.height = ballRadius * scale * 2
    sprite.anchor.set(0.5)

    // Добавляем трейл
    addTrail(ball, sprite.width - 5, sprite.height - 5, ballTexture)
  })

  // Обновляем и удаляем устаревшие части трейла
  updateTrails(ballRef, scale)
}

function addTrail(
  ball: Ball,
  width: number,
  height: number,
  ballTexture: PIXI.Texture
) {
  let trailSprite: PIXI.Sprite
  if (trailPool.length > 0) trailSprite = trailPool.pop()!
  else trailSprite = new PIXI.Sprite(ballTexture)

  trailSprite.x = ball.x
  trailSprite.y = ball.y
  trailSprite.width = width
  trailSprite.height = height
  trailSprite.anchor.set(0.5)
  trailSprite.tint = TRAIL_INITIAL_COLOR
  trailSprite.alpha = 0.5

  trailContainer.addChild(trailSprite)
}

function updateTrails(ballRef: MutableRefObject<Ball[]>, scale: number) {
  for (let i = trailContainer.children.length - 1; i >= 0; i--) {
    const trailSprite = trailContainer.children[i] as PIXI.Sprite
    trailSprite.alpha -= 1 / TRAIL_LENGTH
    if (trailSprite.alpha <= 0) {
      trailContainer.removeChild(trailSprite)
      trailPool.push(trailSprite)
    }
    if (
      ballRef.current.every((ball) => ball.speedX === 0 && ball.speedY === 0)
    ) {
      trailContainer.removeChild(trailSprite)
      trailPool.push(trailSprite)
    }
  }

  // Ограничиваем количество спрайтов в трейле
  while (
    trailContainer.children.length >
    TRAIL_LENGTH * ballRef.current.length
  ) {
    const oldestTrail = trailContainer.removeChildAt(0) as PIXI.Sprite
    trailPool.push(oldestTrail)
  }
}
