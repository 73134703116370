import { AnimationContext } from "@/context/animationContext"
import { Block } from "@/types"
import { blockHeight, blockWidth, bonusBlockRadius } from "@brick/const"
import { Container, Sprite, useTick } from "@pixi/react"
import { MutableRefObject, useContext, useState } from "react"

interface Props {
  blocks: Block[]
  globalScale: number
}

interface ChildrenProps {
  adjustedY: number
  globalScale: number
  scale: number
  x: number
  y: number
}

const BonusBlockContainer = ({ globalScale, blocks }: Props) => {
  const [scale, setScale] = useState(1)
  const { offsetY } = useContext(AnimationContext)

  useTick((delta) => {
    setScale(1 + Math.sin(Date.now() / 200) * 0.1)
  })

  return (
    <Container>
      {blocks.map((block) => {
        if (block.bonus && block.visible) {
          const adjustedY = block.y + offsetY
          return (
            <>
              <OuterBonusBlock
                x={block.x}
                y={block.y}
                adjustedY={adjustedY}
                scale={scale}
                globalScale={globalScale}
              />
              <InnerBonusBlock
                x={block.x}
                y={block.y}
                adjustedY={adjustedY}
                scale={scale}
                globalScale={globalScale}
              />
            </>
          )
        }
      })}
    </Container>
  )
}

const OuterBonusBlock = ({
  x,
  globalScale,
  scale,
  adjustedY,
}: ChildrenProps) => {
  return (
    <Sprite
      x={(x * blockWidth + blockWidth / 2) * globalScale}
      y={(adjustedY * blockHeight + blockHeight / 2) * globalScale}
      image="/images/outerBonusBall.png"
      scale={scale}
      height={bonusBlockRadius * 2.5 * scale}
      width={bonusBlockRadius * 2.5 * scale}
      anchor={0.5}
      tint={0xdff501}
    />
  )
}

const InnerBonusBlock = ({ x, globalScale, adjustedY }: ChildrenProps) => {
  return (
    <Sprite
      x={(x * blockWidth + blockWidth / 2) * globalScale}
      y={(adjustedY * blockHeight + blockHeight / 2) * globalScale}
      image="/images/bonusBall.png"
      height={bonusBlockRadius * globalScale}
      width={bonusBlockRadius * globalScale}
      anchor={0.5}
    />
  )
}

export default BonusBlockContainer
