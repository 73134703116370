import { create, StoreApi, UseBoundStore } from "zustand"

interface Token {
  gameToken: string
  setGameToken: (state: string) => void
  setToken: (state: string) => void
  token: string
}

export const useToken: UseBoundStore<StoreApi<Token>> = create<Token>(
  (set) => ({
    token: "",
    gameToken: "",
    setGameToken: (tkn) => set(() => ({ gameToken: tkn })),
    setToken: (tkn) => set(() => ({ token: tkn })),
  })
)
