export const width = 300
export const height = 300

export const ballRadius = 8
export const ballSpeed = 8

export const blockWidth = width / 6
export const blockHeight = width / 9
export const bonusBlockRadius = 10

export const minVisibleBlocks = 2

export const epsilon = 0.001
