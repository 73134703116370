import { ReactNode } from "react"

interface Props {
  children?: ReactNode
  styles?: string
  title: string
}

const CommonHeader = ({ title, styles, children }: Props) => {
  return (
    <div>
      <h2
        className={`text-start ${styles} font-sfUiBold text-2xl text-black dark:text-white`}
      >
        {title}
        {children}
      </h2>
    </div>
  )
}

export default CommonHeader
