import CardWrapper from "@/components/common/CardWrapper"
import { useTranslation } from "react-i18next"

const Profile = () => {
  const { t } = useTranslation()
  return (
    <div className="w-full flex-col">
      <div className="flex h-[153px] items-start justify-between rounded-b-xl bg-white p-3">
        <div className="bg-light-gray/50 flex items-center rounded-full px-[20px] py-[8px]">
          <ul className="flex items-center justify-between gap-x-[16px]">
            <li className="flex">
              <img src="/images/rocket.png" width={20} height={20} />
              <p className="font-sfUiSemibold">x1</p>
            </li>
            <li className="flex">
              <img src="/images/bomb.png" width={20} height={20} />
              <p className="font-sfUiSemibold">x1</p>
            </li>
            <li className="flex">
              <img src="/images/x2.png" width={20} height={20} />
              <p className="font-sfUiSemibold">x1</p>
            </li>
          </ul>
        </div>
        <div className="bg-light-gray/50 flex items-center rounded-full px-[16px] py-[10px]">
          <p className="font-sfUiSemibold text-sm">1.250</p>
          <div className="ml-2 h-4 w-4">
            <img src="/svg/coin.svg" />
          </div>
        </div>
      </div>
      <div className="absolute left-0 right-0 top-[100px] ml-auto mr-auto h-[107px] w-[107px] rounded-full">
        <img
          className="mb-3 rounded-full"
          width={107}
          height={107}
          src="/images/avatar2.png"
        />
        <h2 className="font-sfUiBold m-0 w-[120px] p-0 text-xl">Ник игрока</h2>
        <p className="text-dark-gray font-sfUiRegular text-sm">Игрок</p>
      </div>
      <div className="mt-[124px] w-full p-3">
        <CardWrapper
          bgColor={{ dark: "bg-button-color", light: "button-color" }}
          styles="bg-button-color p-1.5 rounded-xl"
          image="/images/record.png"
          imageStyle="w-[40px] h-[40px]"
          leftSide={
            <div className="ml-2">
              <h2 className="text-base text-white">{t("game.record")}:</h2>
            </div>
          }
          rightSide={
            <div className="mr-6 flex items-end justify-start">
              <img
                className="mb-1 mr-1"
                width={16}
                height={16}
                src="svg/coin.svg"
              />
              <p className="font-sfUiBold text-2xl text-white">345</p>
            </div>
          }
        />
      </div>
    </div>
  )
}

export default Profile
