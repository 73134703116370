export const dailyBonusMaxTime = 12 * 60 * 60 * 1000
export const dailyBonusReward = 25
export const dailyBonusMinimum = 0.1

export const referrerBonus = 35
export const referrerPremiumBonus = 50
export const referralBonus = 20
export const referralPremiumBonus = 35

export const maxAdsQuantityPerPeriod = 10
export const maxAdsQuantityPeriod = 24 * 60 * 60 * 1000

export const publicationStoryBonus = 30
