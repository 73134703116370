import { create, StoreApi, UseBoundStore } from "zustand"

import { Theme } from "@/types"

export const useTheme: UseBoundStore<StoreApi<Theme>> = create<Theme>(
  (set) => ({
    theme: "",
    setTheme: (newTheme) => set(() => ({ theme: newTheme })),
  })
)
